import React, { useState } from "react";
import "../../App.css";
import { db } from "../../firebase-config";
import { collection, addDoc } from "firebase/firestore";
import { Button, FormControl, Modal, Select, TextField, Typography, InputLabel, MenuItem, Breadcrumbs, Link, Box } from "@mui/material";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import NoCrashIcon from '@mui/icons-material/NoCrash';

const AddAuto = () => {
    const [newMarka, setNewMarka] = useState("");
    const [newModel, setNewModel] = useState("");
    const [newGos, setNewGos] = useState("");
    const [newClasse, setNewClasse] = useState("");
    const [colorAuto, setColorAuto] = useState("");
    const [typeAuto, setTypeAuto] = useState("");
    const [driveAuto, setDriveAuto] = useState("");
    const [fuelAuto, setFuelAuto] = useState("");
    const [vin, setVin] = useState("");
    const [year, setYear] = useState("");
    const [newType, setNewType] = useState("");

    const usersCollectionRef = collection(db, "auto");

    const handleChange = (event) => {
        setNewClasse(event.target.value);
    };

    const colorChange = (event) => {
        setColorAuto(event.target.value);
    };

    const driveChange = (event) => {
        setDriveAuto(event.target.value);
    };

    const fuelChange = (event) => {
        setFuelAuto(event.target.value);
    };


    const typeChange = (event) => {
        setTypeAuto(event.target.value);
    };

    const createAuto = async () => {
        await addDoc(usersCollectionRef, {
            marka: selectedBrand,
            model: selectedModel,
            gos: newGos,
            classe: newClasse,
            type: newType,
            color: colorAuto,
            vin: vin,
            year: year,
            drive: driveAuto,
            fuel: fuelAuto,
            commentAuto: "",
            powerHorse: "",
            statusAuto: "Простой",
            stsNumber: "",
            stsOwner: "",
            stsDateStart: "",
            insuranceCompany: "",
            insuranceNumber: "",
            insuranceDateStart: "",
            insuranceDateEnd: "",
            inspectionСompany: "",
            inspectionDateStart: "",
            inspectionDateEnd: "",
            tireSeason: "",
            tireFront: "",
            tireBack: "",
            tireComment: "",
        });
        if (createAuto) {
            handleOpen();
        } else {
            alert("ошибка загрузки");
        }
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const [selectedBrand, setSelectedBrand] = useState("");
    const [selectedModel, setSelectedModel] = useState("");

    const renderModelOptions = (brand) => {
        switch (brand) {
            case "Mercedes-Benz":
                return (
                    <>
                        <option value="E200">E200</option>
                        <option value="E200d">E200d</option>
                        <option value="E220d">E220d</option>
                        <option value="E300d">E300d</option>
                        <option value="S350d">S350d</option>
                    </>
                );
            case "BMW":
                return (
                    <>
                        <option value="520d">520d</option>
                        <option value="530i">530i</option>
                    </>
                );
            case "Audi":
                return <option value="A6">A6</option>;
            default:
                return null;
        }
    };

    const handleBrandChange = (e) => {
        const brand = e.target.value;
        setSelectedBrand(brand);
        setSelectedModel(""); // Сброс выбранной модели при изменении марки
    };

    const handleModelChange = (e) => {
        const model = e.target.value;
        setSelectedModel(model);
    };

    return (
        <div className="grid">
            <Header />
            <div class='content'>
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Дашборд
                        </Link>
                        <Link underline="hover" color="inherit" href="/auto">
                            Автомобили
                        </Link>
                        <Typography color="text.primary"><b>Создание автомобиля</b></Typography>
                    </Breadcrumbs>
                </div>
                <p className="header-mt">Создание автомобиля</p>
                <div className="info-active-block-table">
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={createAuto}
                        className="btn-save-to-table"
                    >
                        Сохранить
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        href="/auto"
                        className="btn-back-to-table"
                    >
                        Отменить
                    </Button>
                    <div className="drivers-block-btn"></div>
                </div>
                <div className="">
                    <div className="box-create-form">
                        <p className="header-block-t">Информация об авто</p>
                        <div className="date-block-2-long">
                            <p className="text-form">Выберите марку:</p>
                            <select id="brand" value={selectedBrand} onChange={handleBrandChange}>
                                <option value="">Выберите марку</option>
                                <option value="Audi">Audi</option>
                                <option value="BMW">BMW</option>
                                <option value="Mercedes-Benz">Mercedes-Benz</option>
                            </select>
                        </div>
                        <div className="date-block-2-long">
                            <p className="text-form">Выберите модель:</p>
                            <select id="model" value={selectedModel} onChange={handleModelChange}>
                                <option value="">Выберите модель</option>
                                {renderModelOptions(selectedBrand)}
                            </select>
                        </div>

                        {/*   <div className="date-block-2-long">
                            <p className="text-form">Марка:</p>
                            <TextField id="outlined-basic" label="Марка" variant="outlined"
                                size="small"
                                sx={{ width: 1 }}
                                onChange={(event) => {
                                    setNewMarka(event.target.value);
                                }} />
                        </div>
                        <div className="date-block-2-long">
                            <p className="text-form">Модель:</p>
                            <TextField id="outlined-basic" label="Модель" variant="outlined"
                                size="small"
                                sx={{ width: 1 }}
                                onChange={(event) => {
                                    setNewModel(event.target.value);
                                }} />
                        </div>
                        */}
                        <div className="date-block-2-long">
                            <p className="text-form">Гос.номер:</p>
                            <TextField id="outlined-basic" label="Гос.номер" variant="outlined"
                                size="small"
                                sx={{ width: 1 }}
                                onChange={(event) => {
                                    setNewGos(event.target.value);
                                }} />
                        </div>
                        <div className="date-block-2-long">
                            <p className="text-form">VIN номер:</p>
                            <TextField id="outlined-basic" label="Вин номер" variant="outlined"
                                size="small"
                                sx={{ width: 1 }}
                                onChange={(event) => {
                                    setVin(event.target.value);
                                }} />
                        </div>
                        <div className="date-block-2-long">
                            <p className="text-form">Год выпуска:</p>
                            <TextField id="outlined-basic" label="Год выпуска" variant="outlined"
                                size="small"
                                sx={{ width: 1 }}
                                onChange={(event) => {
                                    setYear(event.target.value);
                                }} />
                        </div>
                        <div className="date-block-2-long">
                            <p className="text-form">Привод:</p>
                            <FormControl sx={{ m: 0, minWidth: 200 }} size="small">
                                <InputLabel id="demo-simple-select-label">Привод</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={driveAuto}
                                    label="Привод"
                                    defaultValue={'Передний'}
                                    onChange={driveChange}
                                >
                                    <MenuItem value={'Задний'}>Задний</MenuItem>
                                    <MenuItem value={'Передний'}>Передний</MenuItem>
                                    <MenuItem value={'Полный'}>Полный</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="date-block-2-long">
                            <p className="text-form">Тип двигателя:</p>
                            <FormControl sx={{ m: 0, minWidth: 200 }} size="small">
                                <InputLabel id="demo-simple-select-label">Тип двигателя</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={fuelAuto}
                                    label="Тип двигателя"
                                    defaultValue={'Передний'}
                                    onChange={fuelChange}
                                >
                                    <MenuItem value={'Бензин'}>Бензин</MenuItem>
                                    <MenuItem value={'Дизель'}>Дизель</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="date-block-2-long">
                            <p className="text-form">Тип автомобиля:</p>
                            <FormControl sx={{ m: 0, minWidth: 200 }} size="small">
                                <InputLabel id="demo-simple-select-label">Тип автомобиля</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={typeAuto}
                                    label="Тип автомобиля"
                                    defaultValue={'Бизнес'}
                                    onChange={typeChange}
                                >
                                    <MenuItem value={'Легковой'}>Легковой</MenuItem>
                                    <MenuItem value={'Минивен'}>Минивен</MenuItem>
                                    <MenuItem value={'Автобус'}>Автобус</MenuItem>
                                    <MenuItem value={'Кроссовер'}>Кроссовер</MenuItem>
                                    <MenuItem value={'Грузовик'}>Грузовик</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="date-block-2-long">
                            <p className="text-form">Класс автомобиля:</p>
                            <FormControl sx={{ m: 0, minWidth: 200 }} size="small">
                                <InputLabel id="demo-simple-select-label">Класс</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={newClasse}
                                    label="Класс"
                                    defaultValue={'Бизнес'}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={'Комфорт'}>Комфорт</MenuItem>
                                    <MenuItem value={'Комфорт+'}>Комфорт+</MenuItem>
                                    <MenuItem value={'Бизнес'}>Бизнес</MenuItem>
                                    <MenuItem value={'Премьер'}>Премьер</MenuItem>
                                    <MenuItem value={'Элит'}>Элит</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="date-block-2-long">
                            <p className="text-form">Цвет кузова:</p>
                            <FormControl sx={{ m: 0, minWidth: 100 }} size="small">
                                <InputLabel id="demo-simple-select-label">Цвет кузова</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={colorAuto}
                                    label="Цвет кузова"
                                    defaultValue={'Бизнес'}
                                    onChange={colorChange}
                                >
                                    <MenuItem value={'Чёрный'}>Чёрный</MenuItem>
                                    <MenuItem value={'Белый'}>Белый</MenuItem>
                                    <MenuItem value={'Серый'}>Серый</MenuItem>
                                    <MenuItem value={'Серебристый'}>Серебристый</MenuItem>
                                    <MenuItem value={'БЖС'}>Белый-Желтый-Серый</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style} className="box-modal-succes">
                        <Typography className="modal-grid-succes" id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                            <NoCrashIcon className="modal-grid-succes-icon" />
                            <p className="modal-grid-succes-text">Автомобиль добавлен</p>
                            <p className="modal-grid-succes-text">в базу данных, все отлично!</p>
                        </Typography>
                        <a href="/auto" className="modal-grid-succes-text-a">Вернуться в раздел Автомобили</a>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}

export default AddAuto;
