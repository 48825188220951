import { React, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { Breadcrumbs, Button, Chip, Link, Modal, TextField, TextareaAutosize } from "@mui/material";
import { collection, doc, getDocs, deleteDoc, addDoc } from "firebase/firestore";
import { db } from "../../firebase-config";
import CancelIcon from '@mui/icons-material/Cancel';
import AllInboxRoundedIcon from '@mui/icons-material/AllInboxRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const RepairSettings = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const usersCollectionRef = collection(db, "group_partners");
    const [groupPartner, setGroupPartner] = useState([]);
    const [groupNamePartner, setGroupNamePartner] = useState("");

    const [openAdd, setOpenAdd] = useState(false);
    const handleOpenAdd = (props) => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);


    useEffect(() => {
        const getGroupPartner = async () => {
            const data = await getDocs(usersCollectionRef);
            setGroupPartner(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getGroupPartner();
    }, []);


    const createGroupPartners = async () => {
        await addDoc(usersCollectionRef, {
            name_group_partners: groupNamePartner,
        });
        if (createGroupPartners) {
            window.location.reload();
        } else {
            alert("ошибка загрузки");
        }
    };

    const handleDeleteGroup = async (id) => {
        const userDoc = doc(db, "group_partners", id);
        await deleteDoc(userDoc);
        if (handleDeleteGroup) {
            window.location.reload();
        } else {
            alert("ошибка загрузки");
        }
    };

    return (
        <div className="grid">
            <Header />
            <div class='content'>
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Дашборд
                        </Link>
                        <Link underline="hover" color="inherit" href="/repair">
                            ТО и Ремонты
                        </Link>
                        <Typography color="text.primary"><b>Настройки</b></Typography>
                    </Breadcrumbs>
                </div>
                <div className="main_text_button">
                    <p className="header-mt">Ремонты - настройки</p>
                </div>
                <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
                >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        <Tab label="Группа сервисов" {...a11yProps(0)} />
                        <Tab label="Категории ремонтов" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0} >
                        <h3>Группа сервисов</h3>
                        <p>Здесь вы можете добавить/удалить сервисные центры.</p>
                        <div>
                            {groupPartner.map((item) => (
                                <div className="chip-classe" key={item.id}>
                                    <p className="chip-text">{item.name_group_partners}</p>
                                    <CancelIcon
                                        className="chip-icon"
                                        onClick={() => {
                                            handleDeleteGroup(item.id);
                                        }} />
                                </div>
                            ))}
                            <a className="chip-classe-add" onClick={handleOpenAdd}>
                                <p className="chip-text-add">Добавить</p>
                            </a>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1} >
                        <h3>(в разработке) Категории ремонтов</h3>
                        <p>Здесь вы можете добавить/удалить сервисные центры.</p>
                        <div>
                            {groupPartner.map((item) => (
                                <div className="chip-classe" key={item.id}>
                                    <p className="chip-text">{item.name_group_partners}</p>
                                    <CancelIcon
                                        className="chip-icon"
                                        onClick={() => {
                                            handleDeleteGroup(item.id);
                                        }} />
                                </div>
                            ))}
                            <a className="chip-classe-add" onClick={handleOpenAdd}>
                                <p className="chip-text-add">Добавить</p>
                            </a>
                        </div>
                    </TabPanel>
                </Box>
            </div>
            <Modal
                keepMounted
                open={openAdd}
                onClose={handleCloseAdd}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style} className="box-modal-succes">
                    <div className="box-modal-grid-2-icon-input">
                        <AllInboxRoundedIcon className="icon-modal-add-1-input" />
                        <TextField id="outlined-basic" label="Название группы" variant="outlined"
                            className="adddriver-input"
                            size="small"
                            sx={{ width: 1 }}
                            onChange={(event) => {
                                setGroupNamePartner(event.target.value);
                            }} />
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={createGroupPartners}
                            className="btn-modal-add-group"
                        >
                            <CheckRoundedIcon className="icon-modal-add-2-input" />
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default RepairSettings;
