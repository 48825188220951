import "./App.css";
import LogoutIcon from '@mui/icons-material/Logout';
import avatar from "./assets/ava-driver.png";

const Header = () => {
    const now = new Date().toLocaleDateString(); // date now
    const time = new Date().toLocaleTimeString().slice(0, -3); // time now

    return (
        <div className='header'>
            <div className="block-header">
                <div className="header-date-time">
                    {now}г. {time}
                </div>
                <div className="header-info-user">
                    <img src={avatar} alt="Logo" className="ava-img" />
                    <div><p>Пользователь</p></div>
                    <div>
                        <a href="/drivers" className="text-vert-center"><LogoutIcon className="header-icon" /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
