import React, { useState, useEffect } from "react";
import { db } from "../../firebase-config";
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { Button, Modal, Typography, Breadcrumbs, Link, Box, MenuItem, Select } from "@mui/material";
import { useParams } from "react-router-dom";
import Header from "../../Header";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const DepositView = () => {
    const { id } = useParams(); // Получаем ID из URL
    const [deposit, setDeposit] = useState({
        driver: "",
        amount: "",
        comment: "",
        transactions: [],
        totalDeposit: "",
        status: "Активный"
    });

    const [amount, setAmount] = useState(""); // Добавляем состояние для amount
    const [comment, setComment] = useState(""); // Добавляем состояние для comment
    const [isEditing, setIsEditing] = useState(false); // Состояние для режима редактирования
    const [editValues, setEditValues] = useState({
        driver: "",
        totalDeposit: "",
        mainComment: "",
        status: ""
    });

    // Получение данных о депозите
    useEffect(() => {
        const fetchDeposit = async () => {
            const docRef = doc(db, "deposits", id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setDeposit(docSnap.data());
                setEditValues({
                    driver: docSnap.data().driver,
                    totalDeposit: docSnap.data().totalDeposit,
                    mainComment: docSnap.data().comment,
                    status: docSnap.data().status
                });
            } else {
                console.log("No such document!");
            }
        };
        fetchDeposit();
    }, [id]);

    // Функция для добавления новой транзакции
    const addTransaction = async (newTransaction) => {
        const docRef = doc(db, "deposits", id);
        await updateDoc(docRef, {
            transactions: arrayUnion(newTransaction)
        });
        setDeposit(prev => ({
            ...prev,
            transactions: [...prev.transactions, newTransaction]
        }));
    };

    // Функция для удаления транзакции
    const removeTransaction = async (transaction) => {
        const docRef = doc(db, "deposits", id);
        await updateDoc(docRef, {
            transactions: arrayRemove(transaction)
        });
        setDeposit(prev => ({
            ...prev,
            transactions: prev.transactions.filter(t => t !== transaction)
        }));
    };

    const [openAdd, setOpenAdd] = useState(false);
    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const handleSaveTransaction = async () => {
        const docRef = doc(db, "deposits", id);
        const newTransaction = {
            date: new Date().toISOString(), // Форматирование даты и времени для единообразия
            amount: parseFloat(amount) * (action === 'add' ? 1 : -1), // Умножаем на -1 для списания
            comment: comment,
            action: action
        };

        try {
            await updateDoc(docRef, {
                transactions: arrayUnion(newTransaction)
            });
            alert("Транзакция успешно добавлена!");
            window.location.reload(); // Обновление страницы для отображения изменений
        } catch (error) {
            console.error("Ошибка при добавлении транзакции: ", error);
            alert("Ошибка: " + error.message);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSaveEdit = async () => {
        const docRef = doc(db, "deposits", id);

        try {
            await updateDoc(docRef, {
                driver: editValues.driver,
                totalDeposit: editValues.totalDeposit,
                comment: editValues.mainComment,
                status: editValues.status
            });
            setDeposit(prev => ({
                ...prev,
                driver: editValues.driver,
                totalDeposit: editValues.totalDeposit,
                comment: editValues.mainComment,
                status: editValues.status
            }));
            setIsEditing(false);
            alert("Данные успешно обновлены!");
        } catch (error) {
            console.error("Ошибка при сохранении изменений: ", error);
            alert("Ошибка: " + error.message);
        }
    };

    const [action, setAction] = useState('add'); // Инициализация с 'add'

    const handleChange = (event) => {
        setAction(event.target.value); // Обновление состояния при выборе нового значения
    };

    const handleStatusChange = async (event) => {
        const newStatus = event.target.value;
        const docRef = doc(db, "deposits", id);

        try {
            await updateDoc(docRef, {
                status: newStatus
            });
            setDeposit(prev => ({
                ...prev,
                status: newStatus
            }));
            setEditValues(prev => ({
                ...prev,
                status: newStatus
            }));
            alert(`Статус успешно изменен на ${newStatus}!`);
        } catch (error) {
            console.error("Ошибка при изменении статуса: ", error);
            alert("Ошибка: " + error.message);
        }
    };

    // Функция для вычисления общей суммы транзакций
    const calculateTotalAmount = () => {
        return deposit.transactions.reduce((total, transaction) => total + transaction.amount, 0);
    };

    return (
        <div className="grid">
            <Header />
            <div className='content'>
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Дашборд
                        </Link>
                        <Link underline="hover" color="inherit" href="/deposits">
                            Залоги
                        </Link>
                        <Typography color="text.primary"><b>Просмотр</b></Typography>
                    </Breadcrumbs>
                </div>
                <p className="header-mt">{deposit.driver}</p>
                <div className="info-active-deposit-panel">

                    <Button
                        variant="outlined"
                        size="small"
                        className="btn-add-deposit"
                        onClick={handleOpenAdd}
                    >
                        Добавить
                    </Button>
                    {isEditing ? (
                        <Button
                            variant="outlined"
                            size="small"
                            className="btn-save-deposit"
                            onClick={handleSaveEdit}
                        >
                            Сохранить
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            size="small"
                            className="btn-update-deposit"
                            onClick={handleEdit}
                        >
                            Редактировать
                        </Button>
                    )}
                    <div className="drivers-block-btn"></div>
                </div>
                <div className="box-deposit_grid-2">
                    <div className="box-white">
                        <div className="deposit-text">
                            <p className="text-form f-roboto-500 mb-5px">Статус депозита:</p>
                            <div>
                                <Select
                                    value={editValues.status}
                                    onChange={handleStatusChange}
                                    displayEmpty
                                    className="select-add-techinsp b-none"
                                >
                                    <MenuItem value="Активный">Активный</MenuItem>
                                    <MenuItem value="Не активный">Не активный</MenuItem>
                                </Select>
                            </div>
                        </div>
                        <div className="deposit-text">
                            <p className="text-form f-roboto-500 mb-5px">Водитель:</p>
                            <input
                                className={`input-deposit-none  ${isEditing ? 'editable' : ''} f-roboto-400`}
                                type="text"
                                name="driver"
                                value={isEditing ? editValues.driver : deposit.driver}
                                onChange={(e) => setEditValues({ ...editValues, driver: e.target.value })}
                                readOnly={!isEditing}
                            />
                        </div>
                        <div className="deposit-text">
                            <p className="text-form f-roboto-500 mb-5px">Общая сумма залога:</p>
                            <input
                                className={`input-deposit-none  ${isEditing ? 'editable' : ''} f-roboto-400`}
                                type="text"
                                name="totalDeposit"
                                value={isEditing ? editValues.totalDeposit : deposit.totalDeposit + ' ₽'}
                                onChange={(e) => setEditValues({ ...editValues, totalDeposit: e.target.value })}
                                readOnly={!isEditing}
                            />
                        </div>
                        <div className="deposit-text">
                            <p className="text-form f-roboto-500 mb-5px" >Всего на залоге:</p>
                            <input
                                className="input-deposit-none  f-roboto-400"
                                type="text"
                                name="totalDeposit"
                                value={calculateTotalAmount() + ' ₽'}
                                readOnly
                            />
                        </div>
                        <div>
                            <p className="text-form f-roboto-500 mb-5px">Комментарий по залогу:</p>
                            <textarea
                                className={`textArea_bodyText_tech ${isEditing ? 'editable' : ''}`}
                                name="mainComment"
                                value={isEditing ? editValues.mainComment : deposit.comment || "Комментариев нет..."}
                                onChange={(e) => setEditValues({ ...editValues, mainComment: e.target.value })}
                                readOnly={!isEditing}
                                rows={5} // количество строк в textarea
                                style={{
                                    width: '100%',
                                    padding: '8px',
                                    boxSizing: 'border-box',
                                    resize: 'vertical',
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    backgroundColor: isEditing ? '#fff' : 'transparent',
                                    color: deposit.comment ? 'inherit' : '#999' // цвет текста для отсутствующего комментария
                                }}
                            ></textarea>
                        </div>
                    </div>
                    <div className="box-white mr-15" >
                        <div>
                            <table id="myTable">
                                <thead>
                                    <tr>
                                        <th>Дата/Комментарий</th>
                                        <th>Сумма</th>
                                        <th>Тип операции</th>
                                        <th>Действия</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {deposit.transactions.map((transaction, index) => (
                                        <tr key={index}>
                                            <td>{transaction.comment}</td>
                                            <td>{transaction.amount}</td>
                                            <td>{transaction.action === "add" ? "Пополнение" : "Списание"}</td>
                                            <td className="text-a-end">
                                                <Link onClick={() => removeTransaction(transaction)}
                                                    className="btn-icon-table"
                                                >
                                                    <DeleteOutlineIcon className="delete-icon-deposit" />
                                                </Link >
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Modal
                    keepMounted
                    open={openAdd}
                    onClose={handleCloseAdd}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style} className="box-modal-succes">
                        <div className="">
                            <Select
                                value={action}
                                onChange={handleChange} // Прикрепление функции обработчика
                                displayEmpty
                                className="select_deposit "
                            >
                                <MenuItem value="add">Добавить</MenuItem>
                                <MenuItem value="subtract">Списать</MenuItem>
                            </Select>
                            <div container spacing={2} className="grid-form-add">
                                <div item xs={5}>
                                    <p className="label-input-add-damage">Сумма:</p>
                                </div>
                                <div item xs={5}>
                                    <input
                                        className="input-add-damage"
                                        type="number"
                                        value={amount} // Привязка состояния
                                        onChange={(e) => setAmount(e.target.value)} // Обработчик изменения
                                    />
                                </div>
                            </div>
                            <div container spacing={2} className="grid-form-add">
                                <div item xs={5}>
                                    <p className="label-input-add-damage">Комментарий по внесению д/с:</p>
                                </div>
                                <div item xs={5}>
                                    <input
                                        className="input-add-damage"
                                        type="text"
                                        value={comment} // Привязка состояния
                                        onChange={(e) => setComment(e.target.value)} // Обработчик изменения
                                    />
                                </div>
                            </div>
                        </div>
                        <Button
                            variant="outlined"
                            size="small"
                            className="btn-add-money"
                            onClick={handleSaveTransaction} // Добавление обработчика на кнопку
                        >
                            Сохранить
                        </Button>
                    </Box>
                </Modal>
            </div>
        </div>
    );
};

export default DepositView;
