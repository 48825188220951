import React, { useState, useEffect } from "react";
import "../../App.css";
import "./auto.css";
import { db } from "../../firebase-config";
import {
    collection,
    getDocs,
    getDoc,
    doc,
} from "firebase/firestore";
import { Button, Chip, FormControl, Input, Modal, Select, TextField, Typography, Option, InputLabel, MenuItem, TextareaAutosize, Breadcrumbs, Alert, Link, Box, Grid, FormGroup } from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { useParams } from "react-router-dom";


const initialstate = {
    gos: "",
    categoryRepair: "",
    garage: "",
    mileage: "",
    dateRepair: "",
    timeRepair: "",
    commentRepair: "",
    commentRepairNext: "",
    oilFilter: "",
    oilPlug: "",
    airFilter: "",
    cabinFilter: "",
    frontPads: "",
    rearPads: "",
    frontBrakePads: "",
    rearBrakePads: "",
    dustFilter: "",
}

const ViewRepair = () => {
    const [data, setData] = useState(initialstate);
    const { gos, categoryRepair, garage, mileage, dateRepair, timeRepair, commentRepair, commentRepairNext,
        oilFilter, oilPlug, airFilter, cabinFilter, frontPads, rearPads, frontBrakePads, rearBrakePads, dustFilter } = data;
    const { id } = useParams();

    useEffect(() => {
        id && getsingleUser();
    }, [id]);

    const getsingleUser = async () => {
        const docRef = doc(db, "repair", id);
        const snapshot = await getDoc(docRef);
        if (snapshot.exists()) {
            setData({ ...snapshot.data() });
        }
    };

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const repairCollectionRef = collection(db, "auto");
    const [auto, setAuto] = useState([]);

    useEffect(() => {
        const getRepair = async () => {
            const data = await getDocs(repairCollectionRef);
            setAuto(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getRepair();
    }, []);

    const repairsCollectionRef = collection(db, "repair");
    const [repair, setRepair] = useState([]);

    useEffect(() => {
        const getRepairs = async () => {
            const data = await getDocs(repairsCollectionRef);
            setRepair(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getRepairs();
    }, []);


    return (
        <div className="grid">
            <Header />
            <div class='content'>
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Дашборд
                        </Link>
                        <Link underline="hover" color="inherit" href="/repair">
                            Ремонты
                        </Link>
                        <Typography color="text.primary"><b>Карточка ремонта</b></Typography>
                    </Breadcrumbs>
                </div>
                <p className="header-mt">Карточка ремонта - {gos}</p>
                <div className="info-active-block-table-view-repair">
                    <Link>
                        <Button
                            variant="outlined"
                            size="small"
                            as={Link} to={`/repair_update/${id}`}
                            className="btn-update-repair"
                        >
                            Редактировать
                        </Button>
                    </Link>
                    <div className="drivers-block-btn"></div>
                </div>
                <Box sx={{ width: '100%' }}>


                    <div className="grid-repair-view">
                        <div className="grid-1-block-repair-view">
                            <div className="grid-repair-view ">
                                <div className="gd-flex">
                                    <p className="t-input-vw-repair ">Дата:</p>
                                    <input
                                        className="input-update-auto inp-date-time"
                                        type="date"
                                        name="dateRepair"
                                        value={dateRepair}
                                    />
                                </div>
                                <div className="gd-flex">
                                    <p className="t-input-vw-repair ">Время:</p>
                                    <input
                                        className="input-update-auto inp-date-time"
                                        type="time"
                                        name="timeRepair"
                                        value={timeRepair}
                                    />
                                </div>
                            </div>
                            <div className="grid-repair-view ">
                                <div className="gd-flex">
                                    <p className="t-input-vw-repair ">Категория:</p>
                                    <input
                                        className="input-update-auto inp-date-time"
                                        type="text"
                                        name="categoryRepair"
                                        value={categoryRepair}
                                    />
                                </div>
                                <div className="gd-flex">
                                    <p className="t-input-vw-repair">Гараж:</p>
                                    <input
                                        className="input-update-auto inp-date-time"
                                        type="text"
                                        name="garage"
                                        value={garage}
                                    />
                                </div>
                            </div>
                            <div className="grid-repair-view ">
                                <div className="gd-flex">
                                    <p className="t-input-vw-repair ">Категория:</p>
                                    <input
                                        className="input-update-auto inp-date-time"
                                        type="text"
                                        name="mileage"
                                        value={mileage || "Пробег не вписан"}
                                    />
                                </div>
                                <div className="gd-flex">
                                </div>
                            </div>
                            <div>
                                <p className="text-input-update-auto label-text-view">Расходные материалы:</p>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormGroup>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    className="check-repair"
                                                    name="oilFilter"
                                                    checked={oilFilter}
                                                />
                                                Маслянный фильтр
                                            </label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    className="check-repair"
                                                    name="oilPlug"
                                                    checked={oilPlug}
                                                //  onChange={handleCheckboxChange}
                                                />
                                                Маслянная пробка
                                            </label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    className="check-repair"
                                                    name="airFilter"
                                                    checked={airFilter}
                                                //   onChange={handleCheckboxChange}
                                                />
                                                Воздушный фильтр
                                            </label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    className="check-repair"
                                                    name="cabinFilter"
                                                    checked={cabinFilter}
                                                //     onChange={handleCheckboxChange}
                                                />
                                                Салонный фильтр
                                            </label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    className="check-repair"
                                                    name="dustFilter"
                                                    checked={dustFilter}
                                                //     onChange={handleCheckboxChange}
                                                />
                                                Пылевой фильтр
                                            </label>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormGroup>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    className="check-repair"
                                                    name="frontPads"
                                                    checked={frontPads}
                                                //  onChange={handleCheckboxChange}
                                                />
                                                Передние колодки
                                            </label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    className="check-repair"
                                                    name="rearPads"
                                                    checked={rearPads}
                                                //  onChange={handleCheckboxChange}
                                                />
                                                Задние колодки
                                            </label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    className="check-repair"
                                                    name="frontBrakePads"
                                                    checked={frontBrakePads}
                                                // onChange={handleCheckboxChange}
                                                />
                                                Передние диски
                                            </label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    className="check-repair"
                                                    name="rearBrakePads"
                                                    checked={rearBrakePads}
                                                //  onChange={handleCheckboxChange}
                                                />
                                                Задние диски
                                            </label>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <p className="text-input-update-auto label-text-view">Комментарий по ТО/Ремонту:</p>
                                <textarea
                                    className="textArea-height"
                                    type="text-area"
                                    name="commentRepair"
                                    value={commentRepair}
                                />
                            </div>
                            <div>
                                <p className="text-input-update-auto label-text-view">Рекомендации на след. ТО/Ремонт:</p>
                                <textarea
                                    className="textArea-height"
                                    type="text-area"
                                    name="commentRepairNext"
                                    value={commentRepairNext}
                                />
                            </div>
                        </div>
                        <div className="grid-2-block-repair-view">
                            {repair.map(repair => {
                                if (repair.gos === gos) {
                                    const dateRepairW = new Date(repair.dateRepair.toDate());
                                    // Здесь ваше условие сравнения по `id`
                                    return (
                                        <div key={repair.id}>
                                            <Timeline
                                                sx={{
                                                    [`& .${timelineOppositeContentClasses.root}`]: {
                                                        flex: 0.2,
                                                    },
                                                }}
                                            >
                                                <TimelineItem>
                                                    <TimelineOppositeContent color="textSecondary">
                                                        <b>{dateRepairW.toLocaleDateString()}</b> <br></br>
                                                        <p className="gray-text f-s-12px">{repair.garage || "Гараж не указан"}</p>
                                                        <p className="gray-text f-s-12px">{repair.mileage ? repair.mileage + " км." : "нет пробега"}</p>
                                                    </TimelineOppositeContent>
                                                    <TimelineSeparator>
                                                        <TimelineDot />
                                                        <TimelineConnector />
                                                    </TimelineSeparator>
                                                    <TimelineContent><b>{repair.categoryRepair}</b><br></br> {repair.commentRepair}
                                                        <p>
                                                            {oilFilter && "Маслянный фильтр, "}
                                                            {oilPlug && "Маслянная пробка, "}
                                                            {airFilter && "Воздушный фильтр, "}
                                                            {cabinFilter && "Салонный фильтр, "}
                                                            {frontPads && "Передние колодки, "}
                                                            {rearPads && " Задние колодки, "}
                                                            {frontBrakePads && " Передние диски, "}
                                                            {rearBrakePads && " Задние диски, "}
                                                        </p>
                                                    </TimelineContent>

                                                </TimelineItem>
                                            </Timeline>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>
                </Box>
            </div>
        </div >
    )
}


export default ViewRepair;


