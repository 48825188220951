import { Box, Breadcrumbs, Button, Modal, Typography } from "@mui/material";
import { collection, deleteDoc, doc, getDocs, orderBy, query, updateDoc } from "firebase/firestore";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import { db } from "../../firebase-config";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import avatar from "../../assets/ava-driver.png";

const Drivers = () => {

    const usersCollectionRef = collection(db, "drivers");
    const [drivers, setDrivers] = useState([]);

    const updateUser = async (id, age) => {
        const userDoc = doc(db, "drivers", id);
        const newFields = { age: age + 1 };
        await updateDoc(userDoc, newFields);
    };

    const deleteUser = async (id) => {
        const userDoc = doc(db, "drivers", id);
        await deleteDoc(userDoc);
    };

    useEffect(() => {
        const getUsers = async () => {
            //  const data = await getDocs(usersCollectionRef); 
            const data = await getDocs(query(usersCollectionRef, orderBy('surname')));
            setDrivers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getUsers();
    }, []);

    const deleteDriver = async (id) => {
        const userDoc = doc(db, "drivers", id);
        await deleteDoc(userDoc);
        if (deleteDriver) {
            handleOpen();
        } else {
            alert("ошибка загрузки");
        }
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const start = () => {
        if (drivers.value !== '') {
            return <div><p>Нет</p></div>;
        } else {
            //Else not set CSS rule
        }
    };

    const date = new Date();

    // Получение всех документов из коллекции "auto"
    const getAllDocuments = async () => {
        const autoCollectionRef = collection(db, "auto");
        const querySnapshot = await getDocs(autoCollectionRef);
        const documents = [];

        querySnapshot.forEach((doc) => {
            documents.push({ id: doc.id, data: doc.data() });
        });

        return documents;
    };

    return (
        <div className="grid">
            <Header />
            <div class='content'>
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <a className="breadcrumbs-first" href="/" underline="hover" color="inherit">
                            Дашборд
                        </a>
                        <Typography color="text.primary"><b>Водители</b></Typography>
                    </Breadcrumbs>
                </div>
                <p className="header-mt">Водители</p>

                <div className="info-active-block-table">
                    <Button
                        variant="outlined"
                        size="small"
                        className="btn-add-to-table"
                        as={Link} to={'/add_drivers'}
                    >
                        Добавить
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className="btn-settings-table"
                    >
                        <SettingsIcon className="icon-setting" />
                    </Button>
                    <div className="drivers-block-btn"></div>
                </div>

                <table id="myTable">
                    <tr >
                        <th>Фото</th>
                        <th>Фамилия</th>
                        <th>Имя</th>
                        <th>Отчество</th>
                        <th>Телефон</th>
                        <th>Вод.удост.</th>
                        <th>Комментарий</th>
                        <th>Действия</th>
                    </tr>
                    {drivers.map((drivers) => (
                        <tr key={drivers.id}>
                            <td><img src={avatar} alt="Logo" className="ava-img" /></td>
                            <td>{drivers.surname}</td>
                            <td>{drivers.name}</td>
                            <td>{drivers.patronymic}</td>
                            <td>{start}</td>
                            <td>{drivers.certificate}</td>
                            <td>{drivers.certificate}</td>
                            <td className="td-table-end-action">
                                <Link to={`/drivers_view/${drivers.id}`}
                                    value={drivers.id}>
                                    <Button
                                        variant="none"
                                        size="small"
                                        className="btn-icon-table"
                                    >
                                        <VisibilityIcon className="icon-table" />
                                    </Button>
                                </Link >
                                <Link to={`/drivers_update/${drivers.id}`}
                                    value={drivers.id}>
                                    <Button variant="none" className="btn-icon-table">
                                        <EditIcon className="icon-table" />
                                    </Button>
                                </Link >
                                <Button variant="none" size="small" className="btn-icon-table"
                                    onClick={() => {
                                        deleteDriver(drivers.id);
                                    }}>
                                    <DeleteOutlineIcon className="icon-table" />
                                </Button>
                            </td>
                        </tr>
                    ))}
                </table>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style} className="box-modal-succes">
                        <Typography className="modal-grid-succes" id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                            <HighlightOffIcon className="modal-grid-delete-icon" />
                            <p className="modal-grid-succes-text">Водитель успешно удален!</p>
                        </Typography>
                        <a href="/drivers" className="modal-grid-succes-text-a">Вернуться в раздел "Водители"</a>
                    </Box>
                </Modal>
            </div>
        </div >
    )
}

export default Drivers;
