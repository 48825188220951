import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getStorage } from "firebase/storage"; // Импортируем Firebase Storage

const firebaseConfig = {
  apiKey: "AIzaSyA10fdJ0Lzb9-6ZWH9b7-6RX7A9dePXQss",
  authDomain: "lifedriver-new.firebaseapp.com",
  projectId: "lifedriver-new",
  storageBucket: "lifedriver-new.appspot.com",
  messagingSenderId: "1077102367536",
  appId: "1:1077102367536:web:1010b978219f312edceef5",
};

// Инициализация Firebase
const app = initializeApp(firebaseConfig);

// Экспорт Firestore, Auth и Storage
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app); // Экспортируем Storage