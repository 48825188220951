import { React, useEffect, useState } from "react";
import Header from "./Header";
import "./App.css";
import { getDocs, collection } from "firebase/firestore";
import { db } from "./firebase-config";
import { isBefore, isAfter, addDays, subDays, parseISO } from "date-fns";

const Content = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchAutoData = async () => {
      const autoCollectionRef = collection(db, "auto");
      const autoSnapshot = await getDocs(autoCollectionRef);
      const currentDate = new Date();
      const upcomingDatesRangeStart = subDays(currentDate, 14); // 2 недели до текущей даты
      const upcomingDatesRangeEnd = addDays(currentDate, 14); // 2 недели после текущей даты

      let newMessages = [];

      autoSnapshot.forEach((doc) => {
        const autoData = doc.data();
        const { gos, insuranceDateEnd, yaKontrolDate, inspectionDateEnd } =
          autoData;

        // Проверка для insuranceDateEnd
        if (insuranceDateEnd) {
          const insuranceDate = parseISO(insuranceDateEnd);
          if (
            isBefore(insuranceDate, upcomingDatesRangeEnd) &&
            isAfter(insuranceDate, upcomingDatesRangeStart)
          ) {
            newMessages.push(
              `Заканчивается ОСАГО на автомобиле ${gos}.<br />Дата окончания - ${insuranceDateEnd}.`
            );
          }
        }

        // Проверка для yaKontrolDate
        if (yaKontrolDate) {
          const kontrolDate = parseISO(yaKontrolDate);
          if (
            isBefore(kontrolDate, upcomingDatesRangeEnd) &&
            isAfter(kontrolDate, upcomingDatesRangeStart)
          ) {
            newMessages.push(
              `Скоро ОЧНЫЙ КОНТРОЛЬ ЯНДЕКС на автомобиле ${gos}.<br />Дата осмотра - ${yaKontrolDate}.`
            );
          }
        }

        // Проверка для inspectionDateEnd
        if (inspectionDateEnd) {
          const inspectionDate = parseISO(inspectionDateEnd);
          if (
            isBefore(inspectionDate, upcomingDatesRangeEnd) &&
            isAfter(inspectionDate, upcomingDatesRangeStart)
          ) {
            newMessages.push(
              `Заканчивается ДК на автомобиле ${gos}.<br />Дата окончания - ${inspectionDateEnd}.`
            );
          }
        }
      });

      setMessages(newMessages);
    };

    fetchAutoData();
  }, []);

  return (
    <div className="grid">
      <Header />
      <div className="content">
        <p className="header-mt">События</p>
        <div>
          {messages.length > 0 ? (
            messages.map((message, index) => (
              <div className="block-box-news">
                <p
                  key={index}
                  dangerouslySetInnerHTML={{ __html: message }}
                  className="p-block-news"
                />
              </div>
            ))
          ) : (
            <div className="block-box-news">
              <p className="p-block-news">Нет событий на данный момент.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Content;
