import {
  Box,
  Breadcrumbs,
  Button,
  Modal,
  Typography,
  TextField,
} from "@mui/material";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import "./tasks.css";
import { db } from "../../firebase-config";
import Header from "../../Header";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  listAll,
} from "firebase/storage";

const Disk = () => {
  const [allFolders, setAllFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [openNewFolder, setOpenNewFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [selectedFolderPath, setSelectedFolderPath] = useState("uploadDocs");
  const [folderHierarchy, setFolderHierarchy] = useState([
    { name: "Root", path: "uploadDocs", folders: [] },
  ]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
  };

  // Загрузка всех папок
  const loadAllFolders = async () => {
    try {
      const foldersCollection = collection(db, "folders");
      const foldersSnapshot = await getDocs(foldersCollection);
      const foldersData = foldersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllFolders(foldersData);
      updateFolderHierarchy(foldersData, selectedFolderPath);
    } catch (error) {
      console.error("Error loading folders:", error);
    }
  };

  // Обновление иерархии папок
  const updateFolderHierarchy = (folders, selectedPath) => {
    const pathParts = selectedPath.split("/");
    let currentPath = "";
    const newHierarchy = pathParts.map((part, index) => {
      currentPath = index === 0 ? part : `${currentPath}/${part}`;
      const foldersInPath = folders.filter((f) => f.parentPath === currentPath);
      return {
        name: index === 0 ? "Root" : part,
        path: currentPath,
        folders: foldersInPath,
      };
    });
    setFolderHierarchy(newHierarchy);
  };

  // Загрузка файлов для выбранной папки
  const loadFiles = async (path) => {
    const storage = getStorage();
    const folderRef = ref(storage, path);

    try {
      const result = await listAll(folderRef);
      const filesPromises = result.items.map(async (item) => {
        const url = await getDownloadURL(item);
        return {
          name: item.name,
          path: item.fullPath,
          url: url,
        };
      });
      const filesData = await Promise.all(filesPromises);
      setFiles(filesData);
    } catch (error) {
      console.error("Error loading files:", error);
    }
  };

  useEffect(() => {
    loadAllFolders();
  }, []);

  useEffect(() => {
    if (selectedFolderPath) {
      loadFiles(selectedFolderPath);
      updateFolderHierarchy(allFolders, selectedFolderPath);
    }
  }, [selectedFolderPath]);

  // Создание новой папки
  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) return;

    try {
      const foldersCollection = collection(db, "folders");
      await addDoc(foldersCollection, {
        name: newFolderName,
        parentPath: selectedFolderPath,
        createdAt: new Date(),
      });

      setNewFolderName("");
      setOpenNewFolder(false);
      loadAllFolders();
    } catch (error) {
      console.error("Error creating folder:", error);
    }
  };

  // Загрузка файлов
  const handleFileUpload = async (event) => {
    const files = event.target.files;
    const storage = getStorage();

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileRef = ref(storage, `${selectedFolderPath}/${file.name}`);
        await uploadBytes(fileRef, file);
      }
      loadFiles(selectedFolderPath);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  // Удаление файла
  const handleDeleteFile = async (filePath) => {
    const storage = getStorage();
    const fileRef = ref(storage, filePath);

    try {
      await deleteObject(fileRef);
      loadFiles(selectedFolderPath);
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  // Удаление папки
  const handleDeleteFolder = async (folderId) => {
    try {
      await deleteDoc(doc(db, "folders", folderId));
      loadAllFolders();
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
  };

  // Обработка клика по папке
  const handleFolderClick = (level, folder) => {
    const newPath = `${folder.parentPath}/${folder.name}`;
    setSelectedFolderPath(newPath);
    // Обрезаем иерархию до текущего уровня и добавляем новую папку
    setFolderHierarchy((prev) => [
      ...prev.slice(0, level + 1),
      {
        name: folder.name,
        path: newPath,
        folders: allFolders.filter((f) => f.parentPath === newPath),
      },
    ]);
  };

  return (
    <div className="grid">
      <Header />
      <div className="content">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Дашборд
            </Link>
            <Typography color="text.primary">
              <b>Облачное хранилище</b>
            </Typography>
          </Breadcrumbs>
        </div>

        {/* Панель инструментов */}
        <div className="storage-toolbar">
          <Button
            variant="contained"
            startIcon={<CreateNewFolderIcon />}
            onClick={() => setOpenNewFolder(true)}
            className="toolbar-btn"
          >
            Новая папка
          </Button>
          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUploadIcon />}
            className="toolbar-btn"
          >
            Загрузить файлы
            <input type="file" hidden multiple onChange={handleFileUpload} />
          </Button>
        </div>

        {/* Горизонтальная структура папок */}
        <div className="storage-layout">
          {folderHierarchy.map((level, index) => (
            <div key={level.path} className="folder-column">
              <div className="folder-column-header">
                <Typography variant="subtitle2" className="folder-column-title">
                  {level.name}
                </Typography>
              </div>
              <div className="folder-list">
                {level.folders.map((folder) => (
                  <div
                    key={folder.id}
                    className={`folder-tree-item ${
                      selectedFolderPath ===
                      `${folder.parentPath}/${folder.name}`
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => handleFolderClick(index, folder)}
                  >
                    <FolderIcon className="folder-icon" />
                    <span className="folder-name">{folder.name}</span>
                    {allFolders.some(
                      (f) =>
                        f.parentPath === `${folder.parentPath}/${folder.name}`
                    ) && <ChevronRightIcon className="chevron-icon" />}
                    <DeleteOutlineIcon
                      className="delete-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteFolder(folder.id);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}

          {/* Панель с файлами */}
          <div className="files-panel">
            {files.length === 0 ? (
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{
                  textAlign: "center",
                  padding: "2rem",
                  width: "100%",
                }}
              >
                Нет загруженных файлов
              </Typography>
            ) : (
              <div className="files-grid">
                {files.map((file) => (
                  <div key={file.path} className="file-item">
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="file-link"
                    >
                      <InsertDriveFileIcon className="file-icon" />
                      <span className="file-name">{file.name}</span>
                    </a>
                    <DeleteOutlineIcon
                      className="delete-icon"
                      onClick={() => handleDeleteFile(file.path)}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Модальное окно создания папки */}
        <Modal open={openNewFolder} onClose={() => setOpenNewFolder(false)}>
          <Box sx={style}>
            <Typography variant="h6" component="h2" mb={2}>
              Создать новую папку
            </Typography>
            <TextField
              fullWidth
              label="Название папки"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Button variant="contained" onClick={handleCreateFolder}>
              Создать
            </Button>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default Disk;
