import React, { useState } from "react";
import "../../App.css";
import { db } from "../../firebase-config";
import { collection, addDoc } from "firebase/firestore";
import {
  Button,
  Modal,
  Typography,
  TextareaAutosize,
  Breadcrumbs,
  Link,
  Box,
  Grid,
} from "@mui/material";
import Header from "../../Header";

const AddDeposit = () => {
  const [driver, setDriver] = useState("");
  const [totalDeposit, setTotalDeposit] = useState("");
  const [initialAmount, setInitialAmount] = useState("");
  const [initialComment, setInitialComment] = useState("");
  const [dateAdded, setDateAdded] = useState(new Date().toISOString());

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const createDeposit = async () => {
    const depositData = {
      driver,
      totalDeposit,
      status: "Активный", // Добавляем поле status
      transactions: [
        {
          amount: parseFloat(initialAmount),
          date: dateAdded,
          comment: initialComment,
          action: "add",
        },
      ],
    };

    try {
      await addDoc(collection(db, "deposits"), depositData);
      handleOpen();
    } catch (error) {
      alert("Ошибка загрузки: " + error.message);
    }
  };

  return (
    <div className="grid">
      <Header />
      <div className="content">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Дашборд
            </Link>
            <Link underline="hover" color="inherit" href="/damage">
              ДТП/Повреждение
            </Link>
            <Typography color="text.primary">
              <b>Новый залог</b>
            </Typography>
          </Breadcrumbs>
        </div>
        <p className="header-mt">Добавление нового залога</p>
        <div className="info-active-block-table">
          <Button
            variant="outlined"
            size="small"
            onClick={createDeposit}
            className="btn-save-to-table"
          >
            Сохранить
          </Button>
          <Button
            variant="outlined"
            size="small"
            href="/damage"
            className="btn-back-to-table"
          >
            Отменить
          </Button>
        </div>
        <div className="box-block-edit-params">
          <Grid container spacing={2} className="grid-form-add">
            <Grid item xs={12}>
              <p className="text-form">Водитель:</p>
              <input
                className="input-damage"
                type="text"
                value={driver}
                onChange={(e) => setDriver(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="text-form">Общий залог:</p>
              <input
                className="input-damage"
                type="text"
                value={totalDeposit}
                onChange={(e) => setTotalDeposit(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="text-form">Cумма внесения:</p>
              <input
                className="input-damage"
                type="text"
                value={initialAmount}
                onChange={(e) => setInitialAmount(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="text-form">Дата / Комментарий к внесению:</p>
              <TextareaAutosize
                className="textArea_bodyText_tech"
                value={initialComment}
                onChange={(e) => setInitialComment(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} className="box-modal-succes">
            <Typography
              className="modal-grid-succes"
              id="keep-mounted-modal-description"
              sx={{ mt: 2 }}
            >
              <p className="modal-grid-succes-text">Залог добавлен!</p>
            </Typography>
            <a href="/deposits" className="modal-grid-succes-text-a">
              Вернуться в раздел
            </a>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default AddDeposit;
