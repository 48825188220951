import { Button, TextField } from "@mui/material";
import { React, useContext, useState } from "react";
import "./Login.css";
import mainBMW from "../../assets/bmw740li.avif";
import { auth } from "../../firebase-config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const Login = () => {

    const [error, setErorr] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    const { dispatch } = useContext(AuthContext)

    const handleLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                dispatch({ type: "LOGIN", payload: user });
                navigate("/auto");
                // ...
            })
            .catch((error) => {
                setErorr(true);
            });
    }
    return (
        <div class='login-page-body'>
            <div class='login-page-block-first'>
                <div class='login-page-block-f'>
                    <p className="header-mt">Вход в систему управления Автопарком</p>
                    <p className="mt-podtext">Для входа в систему Автопарк-Поморская введите вашу почту и пароль.</p>
                    <div>
                        <form onSubmit={handleLogin}>
                            <div className="login-btn-block">
                                <TextField sx={{ minWidth: "100%" }}
                                    type="email"
                                    id="outlined-basic"
                                    label="Логин"
                                    variant="outlined"
                                    size="small"
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="login-btn-block">
                                <TextField sx={{ minWidth: "100%" }}
                                    type="password"
                                    onChange={e => setPassword(e.target.value)}
                                    id="outlined-basic" label="Пароль" variant="outlined" size="small" />
                            </div>
                            <div className="grid-dash-free-block">
                                <Button sx={{ minWidth: "100%" }}
                                    type="submit"
                                    variant="contained"
                                    className="btn-enter-sign">Войти</Button>
                            </div>
                            {error &&
                                <p className="error-signin">Вы ввели не верный логин или пароль.</p>
                            }
                        </form>
                    </div>
                    <hr></hr>
                    <p className="mt-podtext">Регистрация новых пользователей не предусмотрена политикой сайта. Для коммерческого использования обратитесь к владельцу.</p>
                </div>
            </div>
            <div class='login-page-block-two'>
                <img src={mainBMW} className="img_singIn" alt="logo" />
            </div>
        </div>
    )
}

export default Login;
