import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import "./auto.css";
import { db } from "../../firebase-config";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  addDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import {
  Typography,
  Breadcrumbs,
  Link,
  Box,
  Button,
  TextField,
} from "@mui/material";
import audiA6 from "../../assets/audiA6.png";
import bmw5 from "../../assets/bmw5r.png";
import w222 from "../../assets/w222-new.avif";
import Lixiang from "../../assets/Lixiang.png";
import chema from "../../assets/razvertka.png";
import photo_dorest_mercedes from "../../assets/w213-dorest.avif";
import photo_w213_new from "../../assets/w213-new.avif";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import Header from "../../Header";
import { useParams } from "react-router-dom";
import logoAuto from "../../assets/mb_logo.webp";
import logoBMW from "../../assets/bmw_logo.webp";
import logoAudi from "../../assets/audi_logo.png";
import logoLixiang from "../../assets/lixiang_logo.png";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Tabs, Tab } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import imageCompression from "browser-image-compression";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DoneIcon from "@mui/icons-material/Done";
import DocumentManager from "./components/DocumentManager";

const initialstate = {
  marka: "",
  model: "",
  gos: "",
  drive: "",
  color: "",
  vin: "",
  year: "",
  classe: "",
  category: "",
  fuel: "",
  tireSeason: "",
  tireFront: "",
  tireBack: "",
  stsNumber: "",
  stsOwner: "",
  stsDateStart: "",
  insuranceCompany: "",
  insuranceNumber: "",
  insuranceDateStart: "",
  insuranceDateEnd: "",
  inspectionСompany: "",
  inspectionDateStart: "",
  inspectionDateEnd: "",
};

const ViewAuto = () => {
  //  Загрузка Свидетельства СТС
  const [fileSTS, setFileSTS] = useState(null);
  const handleFileChangeSTS = (event) => {
    const uploadedFile = event.target.files[0];
    setFileSTS(uploadedFile);
  };

  const handleFileUploadSTS = async () => {
    if (fileSTS) {
      const storage = getStorage();
      const fileName = `STS_${id}`; // Пример имени файла на основе пользователя и идентификатора
      const storageRef = ref(storage, `docs/${id}/${fileName}`);
      await uploadBytes(storageRef, fileSTS);
      console.log("File uploaded successfully");
      window.alert("Файл загружен в базу данных!");
      window.location.reload(); // Перезагрузка страницы
    } else {
      console.log("No file selected");
    }
  };

  const [downloadUrlSTS, setDownloadUrlSTS] = useState(null);
  useEffect(() => {
    const fetchDocumentUrl = async () => {
      const storage = getStorage();
      const fileName = `STS_${id}`; // Пример имени файла
      const storageRef = ref(storage, `docs/${id}/${fileName}`);
      try {
        const url = await getDownloadURL(storageRef);
        setDownloadUrlSTS(url);
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };
    fetchDocumentUrl();
  }, []);

  //  Загрузка Диагностической карты
  const [fileDiagnosticCard, setFileDiagnosticCard] = useState(null);
  const handleFileChangeDiagnosticCard = (event) => {
    const uploadedFile = event.target.files[0];
    setFileDiagnosticCard(uploadedFile);
  };
  const handleFileUploadDiagnosticCard = async () => {
    if (fileDiagnosticCard) {
      const storage = getStorage();
      const fileName = `DiagnosticCard_${id}`; // Пример имени файла на основе пользователя и идентификатора
      const storageRef = ref(storage, `docs/${id}/${fileName}`);
      await uploadBytes(storageRef, fileDiagnosticCard);
      console.log("File uploaded successfully");
      window.alert("Файл загружен в базу данных!");
      window.location.reload(); // Перезагрузка страницы
    } else {
      console.log("No file selected");
    }
  };

  const [downloadUrlDiagnosticCard, setDownloadUrlDiagnosticCard] =
    useState(null);
  useEffect(() => {
    const fetchDocumentUrl = async () => {
      const storage = getStorage();
      const fileName = `DiagnosticCard_${id}`; // Пример имени файла
      const storageRef = ref(storage, `docs/${id}/${fileName}`);

      try {
        const url = await getDownloadURL(storageRef);
        setDownloadUrlDiagnosticCard(url);
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };
    fetchDocumentUrl();
  }, []);
  //  Загрузка ПТС
  const [filePTS, setFilePTS] = useState(null);

  const handleFileChangePTS = (event) => {
    const uploadedFile = event.target.files[0];
    setFilePTS(uploadedFile);
  };

  const handleFileUploadPTS = async () => {
    if (filePTS) {
      const storage = getStorage();
      const fileName = `ПТС_${id}`; // Пример имени файла на основе пользователя и идентификатора
      const storageRef = ref(storage, `docs/${id}/${fileName}`);
      await uploadBytes(storageRef, filePTS);
      console.log("File uploaded successfully");
      window.alert("Файл загружен в базу данных!");
      window.location.reload(); // Перезагрузка страницы
    } else {
      console.log("No file selected");
    }
  };
  const [downloadUrlPTS, setDownloadUrlPTS] = useState(null);
  useEffect(() => {
    const fetchDocumentUrl = async () => {
      const storage = getStorage();
      const fileName = `ПТС_${id}`; // Пример имени файла
      const storageRef = ref(storage, `docs/${id}/${fileName}`);

      try {
        const url = await getDownloadURL(storageRef);
        setDownloadUrlPTS(url);
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };
    fetchDocumentUrl();
  }, []);

  const [downloadUrl, setDownloadUrl] = useState(null);
  useEffect(() => {
    const fetchDocumentUrl = async () => {
      const storage = getStorage();
      const fileName = `ОСАГО_${id}`; // Пример имени файла
      const storageRef = ref(storage, `docs/${id}/${fileName}`);
      try {
        const url = await getDownloadURL(storageRef);
        setDownloadUrl(url);
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };
    fetchDocumentUrl();
  }, []);

  //Загрузка документов СТС
  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
  };

  const handleFileUpload = async () => {
    if (file) {
      const storage = getStorage();
      const fileName = `ОСАГО_${id}`; // Пример имени файла на основе пользователя и идентификатора
      const storageRef = ref(storage, `docs/${id}/${fileName}`);
      await uploadBytes(storageRef, file);
      console.log("File uploaded successfully");
      window.alert("Файл загружен в базу данных!");
      window.location.reload(); // Перезагрузка страницы
    } else {
      console.log("No file selected");
    }
  };

  const [data, setData] = useState(initialstate);
  const {
    marka,
    model,
    gos,
    drive,
    color,
    vin,
    classe,
    year,
    category,
    fuel,
    tireSeason,
    tireFront,
    tireBack,
    stsNumber,
    stsOwner,
    stsDateStart,
    insuranceCompany,
    insuranceNumber,
    insuranceDateStart,
    insuranceDateEnd,
    inspectionСompany,
    inspectionDateStart,
    inspectionDateEnd,
  } = data;
  const { id } = useParams();

  useEffect(() => {
    id && getsingleUser();
  }, [id]);

  const getsingleUser = async () => {
    const docRef = doc(db, "auto", id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const repairCollectionRef = collection(db, "auto");
  const [auto, setAuto] = useState([]);

  useEffect(() => {
    const getRepair = async () => {
      const data = await getDocs(repairCollectionRef);
      setAuto(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getRepair();
  }, []);

  const repairsCollectionRef = collection(db, "repair");
  const [repair, setRepair] = useState([]);

  useEffect(() => {
    const getRepairs = async () => {
      const data = await getDocs(repairsCollectionRef);
      setRepair(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getRepairs();
  }, []);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Повреждения на фото
  const [points, setPoints] = useState([]);
  const [pointCounter, setPointCounter] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "auto", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.paintDamages && data.paintDamages.length > 0) {
          setPoints(data.paintDamages);
          const maxLabel = Math.max(...data.paintDamages.map((p) => p.label));
          setPointCounter(maxLabel + 1);
        }
      } else {
        console.log("No such document!");
      }
    };

    fetchData();
  }, [id]);

  const handleImageClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const newPoint = { x, y, label: pointCounter, text: "", expanded: false };

    setPoints((prevPoints) => [...prevPoints, newPoint]);
    setPointCounter((prevCounter) => prevCounter + 1);
  };

  const handleInputChange = (index, event) => {
    const newPoints = points.map((point, i) => {
      if (i === index) {
        return { ...point, text: event.target.value };
      }
      return point;
    });
    setPoints(newPoints);
  };

  const handleDeletePoint = (index) => {
    const updatedPoints = points
      .filter((_, i) => i !== index)
      .map((point, idx) => ({ ...point, label: idx + 1 }));
    setPoints(updatedPoints);
    updateDoc(doc(db, "auto", id), { paintDamages: updatedPoints });
    if (updatedPoints.length === 0) {
      setPointCounter(1);
    }
  };

  const handleSave = async () => {
    const docRef = doc(db, "auto", id);
    try {
      await updateDoc(docRef, { paintDamages: points });
      console.log("Данные успешно сохранены!");
      alert("Данные успешно сохранены!");
    } catch (error) {
      console.error("Ошибка при сохранении данных: " + error.message);
      alert("Ошибка при сохранении данных: " + error.message);
    }
  };

  // Загрузка фоток
  const [photos, setPhotos] = useState([]);
  const [note, setNote] = useState("");
  const [date, setDate] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [inspections, setInspections] = useState([]);
  const storage = getStorage();

  useEffect(() => {
    const fetchInspections = async () => {
      const docRef = doc(db, "auto", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setInspections(docSnap.data().inspections || []);
      } else {
        console.log("No such document!");
      }
    };
    fetchInspections();
  }, [id]);

  const handleFileChangeOsmotr = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    const newPhotos = files.map((file) => URL.createObjectURL(file));
    setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
  };

  const handleFileUploadOsmotr = async () => {
    if (selectedFiles.length > 0 && date) {
      const uploadedPhotos = await Promise.all(
        selectedFiles.map(async (file) => {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
          };
          try {
            const compressedFile = await imageCompression(file, options);
            const filePath = `docs/${id}/osmotr/${date}/${compressedFile.name}`;
            const storageRef = ref(storage, filePath);

            await uploadBytes(storageRef, compressedFile);
            const fileUrl = await getDownloadURL(storageRef);

            return {
              date: date,
              note: note,
              url: fileUrl,
            };
          } catch (error) {
            console.error("Error compressing file:", error);
          }
        })
      );

      const autoDocRef = doc(db, "auto", id);
      await updateDoc(autoDocRef, {
        inspections: arrayUnion(...uploadedPhotos),
      });

      setSelectedFiles([]);
      setDate("");
      setNote("");
      setInspections((prevInspections) => [
        ...prevInspections,
        ...uploadedPhotos,
      ]);
    } else {
      alert("Please select files and enter a date.");
    }
  };

  // Функция для загрузки нового файла с сохранением старого файла как -old
  const handleReplaceFile = async (fileType, newFile) => {
    if (!newFile) {
      console.log("No new file selected");
      return;
    }

    const storage = getStorage();
    const fileName = `${fileType}_${id}`; // Имя файла

    try {
      // Загружаем новый файл (перезаписываем старый)
      const newStorageRef = ref(storage, `docs/${id}/${fileName}`);
      await uploadBytes(newStorageRef, newFile);
      const newFileUrl = await getDownloadURL(newStorageRef);

      // Обновляем состояние с новым URL
      if (fileType === "STS") setDownloadUrlSTS(newFileUrl);
      else if (fileType === "PTS") setDownloadUrlPTS(newFileUrl);
      else if (fileType === "ОСАГО") setDownloadUrl(newFileUrl);
      else if (fileType === "DiagnosticCard")
        setDownloadUrlDiagnosticCard(newFileUrl);

      alert("Файл успешно заменен!");
      window.location.reload(); // Перезагрузка страницы для обновления кэша и URL
    } catch (error) {
      console.error("Error replacing file:", error);
      alert("Ошибка при замене файла");
    }
  };

  return (
    <div className="grid">
      <Header />
      <div className="content">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Дашборд
            </Link>
            <Link underline="hover" color="inherit" href="/auto">
              Автомобили
            </Link>
            <Typography color="text.primary">
              <b>Карточка автомобиля</b>
            </Typography>
          </Breadcrumbs>
        </div>
        <p className="header-mt">
          <div className="table-date-text-gray">
            {(marka === "BMW" && (
              <img src={logoBMW} alt="Logo" className="ava-img" />
            )) ||
              (marka === "Audi" && (
                <img src={logoAudi} alt="Logo" className="ava-img" />
              )) ||
              (marka === "Lixiang" && (
                <img src={logoLixiang} alt="Logo" className="ava-img" />
              )) ||
              (marka === "Audi" && (
                <img src={logoAudi} alt="Logo" className="ava-img" />
              )) || <img src={logoAuto} alt="Logo" className="ava-img" />}
          </div>
          {marka} {model} - {gos}
        </p>
        <Tabs
          className="mb-10"
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Основная информация" />
          <Tab label="Схема повреждений" />
          <Tab label="Осмотры" />
        </Tabs>
        <Box sx={{ width: "100%" }}>
          {tabValue === 0 && (
            <div className="gd-1-2">
              <div className="div1">
                <div className="img-block">
                  {(model === "E220d" && year >= "2020" && (
                    <img src={photo_w213_new} className="photo_for_card_view" />
                  )) ||
                    (model === "E200d" && (
                      <img
                        src={photo_dorest_mercedes}
                        className="photo_for_card_view"
                      />
                    )) ||
                    (model === "A6" && (
                      <img src={audiA6} className="photo_for_card_view" />
                    )) ||
                    (marka === "BMW" && (
                      <img src={bmw5} className="photo_for_card_view" />
                    )) ||
                    (marka === "Lixiang" && (
                      <img src={Lixiang} className="photo_for_card_view" />
                    )) ||
                    (model === "S350d" && (
                      <img src={w222} className="photo_for_card_view" />
                    ))}
                </div>
                <p className="f-view-title">📄 Характеристики</p>
                <div className="flex-two-info">
                  <p className="p-mini">Vin:</p>
                  <p className="text-view-auto-white">{vin}</p>
                </div>
                <hr className="hr-view-auto"></hr>
                <div className="flex-two-info">
                  <p className="p-mini">Двигатель:</p>
                  <p className="text-view-auto-white">{fuel}</p>
                </div>
                <hr className="hr-view-auto"></hr>
                <div className="flex-two-info">
                  <p className="p-mini">Привод:</p>
                  <p className="text-view-auto-white">{drive}</p>
                </div>
                <hr className="hr-view-auto"></hr>
                <div className="flex-two-info">
                  <p className="p-mini">Год выпуска:</p>
                  <p className="text-view-auto-white">{year}</p>
                </div>
                <hr className="hr-view-auto"></hr>
                <div className="flex-two-info">
                  <p className="p-mini">Сезон резины:</p>
                  <p className="text-view-auto-white">
                    {(tireSeason === "" && (
                      <p className="text-view-auto">Нет данных</p>
                    )) ||
                      (tireSeason === tireSeason && (
                        <p className="text-view-auto-white">{tireSeason}</p>
                      ))}
                  </p>
                </div>
                <hr className="hr-view-auto"></hr>
                <div className="flex-two-info">
                  <p className="p-mini">Передняя ось (размер):</p>
                  <p className="text-view-auto-white">
                    {(tireFront === "" && (
                      <p className="text-view-auto">Нет данных</p>
                    )) ||
                      (tireFront === tireFront && (
                        <p className="text-view-auto-white">{tireFront}</p>
                      ))}
                  </p>
                </div>
                <hr className="hr-view-auto"></hr>
                <div className="flex-two-info">
                  <p className="p-mini">Задняя ось (размер):</p>
                  <p className="text-view-auto-white">
                    {(tireBack === "" && (
                      <p className="text-view-auto">Нет данных</p>
                    )) ||
                      (tireBack === tireBack && (
                        <p className="text-view-auto-white">{tireBack}</p>
                      ))}
                  </p>
                </div>
                <p className="f-view-title">📄 Свидетельство CТС</p>
                <div className="flex-two-info">
                  <p className="p-mini">Номер:</p>
                  <p className="text-view-auto-white">
                    {(stsNumber === "" && (
                      <p className="text-view-auto">Нет данных</p>
                    )) ||
                      (stsNumber === stsNumber && (
                        <p className="text-view-auto-white">{stsNumber}</p>
                      ))}
                  </p>
                </div>
                <hr className="hr-view-auto"></hr>
                <div className="flex-two-info">
                  <p className="p-mini">Владелец:</p>
                  <p className="text-view-auto-white">
                    {(stsOwner === "" && (
                      <p className="text-view-auto">Нет данных</p>
                    )) ||
                      (stsOwner === stsOwner && (
                        <p className="text-view-auto-white">{stsOwner}</p>
                      ))}
                  </p>
                </div>
                <hr className="hr-view-auto"></hr>
                <div className="flex-two-info">
                  <p className="p-mini">Дата выдачи:</p>
                  <p className="text-view-auto-white">
                    {(stsDateStart === "" && (
                      <p className="text-view-auto">Нет данных</p>
                    )) ||
                      (stsDateStart === stsDateStart && (
                        <p className="text-view-auto-white">{stsDateStart}г.</p>
                      ))}
                  </p>
                </div>
                <p className="f-view-title">📄 Страховой полис</p>
                <div className="flex-two-info">
                  <p className="p-mini">Компания:</p>
                  <p className="text-view-auto-white">
                    {(insuranceCompany === "" && (
                      <p className="text-view-auto">Нет данных</p>
                    )) ||
                      (insuranceCompany === insuranceCompany && (
                        <p className="text-view-auto-white">
                          {insuranceCompany}
                        </p>
                      ))}
                  </p>
                </div>
                <hr className="hr-view-auto"></hr>
                <div className="flex-two-info">
                  <p className="p-mini">Номер:</p>
                  <p className="text-view-auto-white">
                    {(insuranceNumber === "" && (
                      <p className="text-view-auto">Нет данных</p>
                    )) ||
                      (insuranceNumber === insuranceNumber && (
                        <p className="text-view-auto-white">
                          {insuranceNumber}
                        </p>
                      ))}
                  </p>
                </div>
                <hr className="hr-view-auto"></hr>
                <div className="flex-two-info">
                  <p className="p-mini">Дата окончания:</p>
                  <p className="text-view-auto-white">
                    {(insuranceDateEnd === "" && (
                      <p className="text-view-auto">Нет данных</p>
                    )) ||
                      (insuranceDateEnd === insuranceDateEnd && (
                        <p className="text-view-auto-white">
                          {insuranceDateEnd}г.
                        </p>
                      ))}
                  </p>
                </div>

                <p className="f-view-title">📄 Диагностическая карта</p>
                <div className="flex-two-info">
                  <p className="p-mini">Где делали:</p>
                  <p className="text-view-auto-white">
                    {(inspectionСompany === "" && (
                      <p className="text-view-auto">Нет данных</p>
                    )) ||
                      (inspectionСompany === inspectionСompany && (
                        <p className="text-view-auto-white">
                          {inspectionСompany}
                        </p>
                      ))}
                  </p>
                </div>
                <hr className="hr-view-auto"></hr>
                <div className="flex-two-info">
                  <p className="p-mini">Дата от:</p>
                  <p className="text-view-auto-white">
                    {(inspectionDateStart === "" && (
                      <p className="text-view-auto">Нет данных</p>
                    )) ||
                      (inspectionDateStart === inspectionDateStart && (
                        <p className="text-view-auto-white">
                          {inspectionDateStart}
                        </p>
                      ))}
                  </p>
                </div>
                <hr className="hr-view-auto"></hr>
                <div className="flex-two-info">
                  <p className="p-mini">Окончание:</p>
                  <p className="text-view-auto-white">
                    {(inspectionDateEnd === "" && (
                      <p className="text-view-auto">Нет данных</p>
                    )) ||
                      (inspectionDateEnd === inspectionDateEnd && (
                        <p className="text-view-auto-white">
                          {inspectionDateEnd}
                        </p>
                      ))}
                  </p>
                </div>
              </div>
              <div className="div2">
                <DocumentManager id={id} />
              </div>
              <div className="div3">
                <div>
                  {repair.map((repair) => {
                    const dateRepairW = new Date(repair.dateRepair.toDate());
                    if (repair.gos === gos) {
                      // Здесь ваше условие сравнения по `id`
                      return (
                        <div key={repair.id}>
                          <Timeline
                            sx={{
                              [`& .${timelineOppositeContentClasses.root}`]: {
                                flex: 0.2,
                              },
                            }}
                          >
                            <TimelineItem>
                              <TimelineOppositeContent color="textSecondary">
                                <b>{dateRepairW.toLocaleDateString()}</b>{" "}
                                <br></br>
                                {repair.mileage} км.
                                <br></br> {repair.garage}
                              </TimelineOppositeContent>
                              <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                              </TimelineSeparator>
                              <TimelineContent>
                                <b>{repair.categoryRepair}</b>
                                <br></br> {repair.commentRepair}
                              </TimelineContent>
                            </TimelineItem>
                          </Timeline>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          )}
          {tabValue === 1 && (
            <div className="gd-1-2">
              <div
                className="block-paint-damage"
                onClick={handleImageClick}
                style={{ position: "relative" }}
              >
                <img
                  src={chema}
                  className="photo_for_card_view"
                  alt="Clickable area"
                />
                {points.map((point, index) => (
                  <span
                    key={index}
                    style={{
                      position: "absolute",
                      left: `${point.x}px`,
                      top: `${point.y}px`,
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      backgroundColor: "black",
                      transform: "translate(-50%, -50%)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: " 14px",
                      fontWeight: "bold",
                    }}
                  >
                    {point.label} {/* Отображение номера внутри точки */}
                  </span>
                ))}
              </div>
              <div className="div2">
                <div className="grid-2col">
                  <p>Список повреждений</p>
                  <button onClick={handleSave} className="btn-add-damage-paint">
                    Сохранить
                  </button>
                </div>
                {points.map((point, index) => (
                  <div key={index} className="block-damage-list">
                    <div className="block-jcsb">
                      <div className="d-flex">
                        <p className="point-text-radius">{point.label}</p>
                        <input
                          className="text-area-damage"
                          type="text"
                          value={point.text}
                          placeholder={`Введите повреждения для точки ${point.label}`}
                          onChange={(event) => handleInputChange(index, event)}
                        />
                        <p
                          className="point-text-radius"
                          onClick={() => handleDeletePoint(index)}
                        >
                          Х
                        </p>
                      </div>
                    </div>
                    {point.expanded && (
                      <div>
                        <p className="point-text-mini">Загруженные файлы:</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          {tabValue === 2 && (
            <div className="gd-1-2">
              <div className="div1">
                <p>История осмотров</p>
                {inspections.map((inspection, index) => (
                  <div key={index} className="inspection-item">
                    <p>от {inspection.date}</p>
                    <p>Примечание: {inspection.note}</p>
                    <div className="photos-grid">
                      {inspection.url && (
                        <div className="photo-item">
                          <img
                            src={inspection.url}
                            alt={`Inspection ${index}`}
                            className="photo"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="div2">
                <div className="view-osmotr">
                  <TextField
                    label="Дата осмотра"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                  <TextField
                    label="Примечание"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFileUploadOsmotr}
                    fullWidth
                    style={{ marginTop: "10px" }}
                  >
                    Загрузить фото
                  </Button>
                </div>
                <div className="photos-grid">
                  {photos.map((photo, index) => (
                    <div key={index} className="photo-item">
                      <img
                        src={photo}
                        alt={`Inspection ${index}`}
                        className="photo"
                      />
                    </div>
                  ))}
                  <div className="photo-item empty">
                    <Button
                      variant="outlined"
                      component="label"
                      className="add-photo-button"
                    >
                      <AddPhotoAlternateIcon />
                      <input
                        type="file"
                        hidden
                        multiple
                        onChange={handleFileChangeOsmotr}
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Box>
      </div>
    </div>
  );
};

export default ViewAuto;
