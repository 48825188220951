import { React, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { Breadcrumbs, Chip, Link, Modal, TextField } from "@mui/material";
import { collection, doc, getDocs, deleteDoc, addDoc } from "firebase/firestore";
import { db } from "../../firebase-config";
import CancelIcon from '@mui/icons-material/Cancel';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function Settings() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const usersCollectionRef = collection(db, "classeAuto");
    const [classeAuto, setClasseAuto] = useState([]);

    useEffect(() => {
        const getClasse = async () => {
            const data = await getDocs(usersCollectionRef);
            setClasseAuto(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getClasse();
    }, []);

    const handleDeleteClasse = async (id) => {
        const userDoc = doc(db, "classeAuto", id);
        await deleteDoc(userDoc);
        if (handleDeleteClasse) {
            alert("Удалено!");
        } else {
            alert("ошибка загрузки");
        }
    };

    return (
        <div className="grid">
            <Header />
            <div class='content'>
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Дашборд
                        </Link>
                        <Typography color="text.primary"><b>Настройки Админки</b></Typography>
                    </Breadcrumbs>
                </div>
                <div className="main_text_button">
                    <p className="header-mt">Настройки</p>
                </div>
                <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
                >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        <Tab label="Список классов" {...a11yProps(0)} />
                        <Tab label="Тип транспорта" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <h3>Список классов</h3>
                        <p>Здесь вы можете настроить свои классы автомобилей.</p>
                        <div>
                            {classeAuto.map((item) => (
                                <div className="chip-classe" key={item.id}>
                                    <p className="chip-text">{item.classe_name}</p>
                                    <CancelIcon
                                        className="chip-icon"
                                        onClick={() => {
                                            handleDeleteClasse(item.id);
                                        }} />
                                </div>
                            ))}
                            <a className="chip-classe-add">
                                <p className="chip-text-add">Добавить</p>
                            </a>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        Item Two
                    </TabPanel>
                </Box>
            </div>
        </div>
    );

}