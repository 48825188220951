import "./auto.css";
import { Avatar, Box, Breadcrumbs, Button, Checkbox, Chip, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, TextareaAutosize, Typography } from "@mui/material";
import { collection, deleteDoc, doc, getDocs, updateDoc, addDoc, query, serverTimestamp, Firestore, Timestamp } from "firebase/firestore";
import { React, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import "../../App.css";
import { db } from "../../firebase-config";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { Link } from "react-router-dom";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SettingsIcon from '@mui/icons-material/Settings';

const RepairList = () => {
    const usersCollectionRef = collection(db, "auto");
    useEffect(() => {
        const getUsers = async () => {
            const data = await getDocs(usersCollectionRef);
            setAuto(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getUsers();
    }, []);

    const [repair, setRepair] = useState([]); // Автомобиль
    const repairCollectionRef = collection(db, "repair");
    useEffect(() => {
        const getRepair = async () => {
            const data = await getDocs(repairCollectionRef);
            setRepair(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getRepair();
    }, []);

    const createTechInsp = async () => {
        await addDoc(repairCollectionRef, {
            dateRepair: Timestamp.fromDate(new Date(dateRepair)),
            timeRepair: timeRepair,
            categoryRepair: categoryRepair,
            gos: auto, // гос.номер,
            mileage: mileage, // пробег,
            garage: service,
            commentRepair: commentRepair,
            commentRepairNext: commentRepairNext,
            oilFilter: checkboxes.oilFilter,
            oilPlug: checkboxes.oilPlug,
            airFilter: checkboxes.airFilter,
            cabinFilter: checkboxes.cabinFilter,
            frontPads: checkboxes.frontPads,
            rearPads: checkboxes.rearPads,
            frontBrakePads: checkboxes.frontBrakePads,
            rearBrakePads: checkboxes.rearBrakePads,
            fuelFilter: checkboxes.fuelFilter,
            washingParticulateFilter: checkboxes.washingParticulateFilter,
            dustFilter: checkboxes.dustFilter,
            //
        });
        if (createTechInsp) {
            window.location.reload();
        } else {
            alert("ошибка загрузки");
        }
    };

    const deleteAuto = async (id) => {
        const userDoc = doc(db, "repair", id);
        await deleteDoc(userDoc);
        if (deleteAuto) {
            handleOpen();
        } else {
            alert("ошибка загрузки");
        }
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 14,
        p: 4,
    };

    const [openAdd, setOpenAdd] = useState(false);
    const handleOpenAdd = (props) => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);

    const autoCollectionRef = collection(db, "auto");
    const [auto, setAuto] = useState([]); // Автомобиль
    const [newAuto, setNewAuto] = useState([]); // Автомобиль 
    const [mileage, setMileage] = useState("");// Пробег
    const [dateRepair, setDateRepair] = useState("");// Дата ремонта
    const [timeRepair, setTimeRepair] = useState("");// Время заезда 
    const [commentRepair, setCommentRepair] = useState("");
    const [commentRepairNext, setCommentRepairNext] = useState("");
    const [categoryRepair, setCategoryRepair] = useState(""); // Категория ремонта
    const changeCategory = (event) => {
        setCategoryRepair(event.target.value);
    };
    const [newServiceCenter, setServiceCenter] = useState([]); // Сервисный центр (вытащить из бд)
    const [service, setService] = useState([]); // Сервисный центр (запись)
    const serviceCollectionRef = collection(db, "group_partners");
    useEffect(() => {
        const getService = async () => {
            const data = await getDocs(serviceCollectionRef);
            setServiceCenter(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getService();
    }, []);

    const handleService = (event) => {
        setService(event.target.value);
    };

    const handleCar = (event) => {
        setAuto(event.target.value);
    };

    useEffect(() => {
        const getAuto = async () => {
            const data = await getDocs(autoCollectionRef);
            setNewAuto(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getAuto();
    }, []);

    const [checkboxes, setCheckboxes] = useState({
        oilFilter: false,
        oilPlug: false,
        airFilter: false,
        cabinFilter: false,
        frontPads: false,
        rearPads: false,
        frontBrakePads: false,
        rearBrakePads: false,
        washingParticulateFilter: false,
        fuelFilter: false,
        dustFilter: false,
    });

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [name]: checked,
        }));
    };

    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredRepair = repair.filter((auto) =>
        auto.gos.toLowerCase().includes(searchQuery.toLowerCase())
    );


    return (
        <div className="grid">
            <Header />
            <div class='content'>
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <a className="breadcrumbs-first" href="/" underline="hover" color="inherit">
                            Дашборд
                        </a>
                        <Typography color="text.primary"><b>ТО и Ремонты</b></Typography>
                    </Breadcrumbs>
                </div>
                <p className="header-mt">ТО и Ремонты</p>
                <div className="info-active-block-table">
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleOpenAdd}
                        className="btn-add-to-table"
                    >
                        Добавить
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className="btn-settings-table"
                        as={Link} to={'/repair_settings'}
                    >
                        <SettingsIcon className="icon-setting" />
                    </Button>
                    <div className="drivers-block-btn"></div>
                    <input type="text" className="search-input-auto" value={searchQuery} onChange={handleSearchChange} placeholder="Поиск по гос.номеру" />
                </div>
                <table id="myTable">
                    <tr >
                        <th>Дата</th>
                        <th>Гос.номер</th>
                        <th>Категория</th>
                        <th>Пробег</th>
                        <th>Гараж</th>
                        <th>Комментарий</th>
                        <th>Действия</th>
                    </tr>
                    {filteredRepair.sort((a, b) => b.dateRepair.toDate() - a.dateRepair.toDate()).map((auto) => {
                        const dateRepair = new Date(auto.dateRepair.toDate());
                        const newDate = new Date(dateRepair.getTime());
                        newDate.setDate(dateRepair.getDate() + 40);

                        return (
                            <tr key={auto.id} >
                                <td>{dateRepair.toLocaleDateString()}</td>
                                <td><p className="gos-b">{auto.gos}</p></td>
                                <td>{auto.categoryRepair}</td>
                                <td>{auto.mileage}</td>
                                <td>{auto.garage}</td>
                                <td>{auto.commentRepair}</td>
                                <td className="td-table-end-action">
                                    <Link to={`/repair_view/${auto.id}`}
                                        value={auto.id}
                                        className="btn-icon-table">
                                        <VisibilityIcon className="icon-table" />
                                    </Link>
                                    <Link to={`/repair_update/${auto.id}`}
                                        value={auto.id}
                                        className="btn-icon-table">
                                        <EditIcon className="icon-table" />
                                    </Link>
                                    <Link
                                        onClick={() => { deleteAuto(auto.id); }}
                                        value={auto.id}
                                        className="btn-icon-table">
                                        <DeleteOutlineIcon className="icon-table" />
                                    </Link>
                                </td>
                            </tr>
                        );
                    })}
                </table>
                <Modal
                    keepMounted
                    open={openAdd}
                    onClose={handleCloseAdd}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style} className="box-modal-succes">
                        <p className="header-modal-TechInsp">СОЗДАНИЕ КАРТОЧКИ ТО</p>
                        <div className="">
                            <Grid container spacing={2} className="grid-form-add">
                                <Grid item xs={4}>
                                    <InputLabel id="demo-simple-select-label" className="label_form-add">Дата ТО</InputLabel>
                                    <TextField type="date" id="outlined-basic" variant="outlined"
                                        className="adddriver-input"
                                        size="small"
                                        sx={{ width: 1 }}
                                        Value={dateRepair}
                                        onChange={(event) => {
                                            setDateRepair(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputLabel id="demo-simple-select-label" className="label_form-add">Время</InputLabel>
                                    <TextField type="time" id="outlined-basic" variant="outlined"
                                        className="adddriver-input"
                                        size="small"
                                        sx={{ width: 1 }}
                                        Value={timeRepair}
                                        onChange={(event) => {
                                            setTimeRepair(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputLabel id="demo-simple-select-label" className="label_form-add">Категория</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        className="select-add-techinsp"
                                        value={categoryRepair}
                                        defaultValue={'Легковой авто'}
                                        onChange={changeCategory}
                                    >
                                        <MenuItem value={'ТО'}>ТО</MenuItem>
                                        <MenuItem value={'Ремонт'}>Ремонт</MenuItem>
                                        <MenuItem value={'Покраска'}>Покраска</MenuItem>
                                        <MenuItem value={'Шиномонтаж'}>Шиномонтаж</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className="grid-form-add">
                                <Grid item xs={4}>
                                    <InputLabel id="demo-simple-select-label" className="label_form-add">Гос.номер авто</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        Value={auto}
                                        onChange={handleCar}
                                        helperText="Some important text"
                                        className="select-add-techinsp"
                                    >
                                        {newAuto.map((item) => (
                                            <MenuItem value={item.gos}>{item.gos}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={4}>
                                    <InputLabel id="demo-simple-select-label" className="label_form-add">Пробег</InputLabel>
                                    <TextField id="outlined-basic" variant="outlined"
                                        className="select-add-techinsp"
                                        size="small"
                                        sx={{ width: 1 }}
                                        Value={mileage}
                                        onChange={(event) => {
                                            setMileage(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputLabel id="demo-simple-select-label" className="label_form-add">Автосервис</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        Value={service}
                                        onChange={handleService}
                                        helperText="Some important text"
                                        className="select-add-techinsp"
                                    >
                                        {newServiceCenter.map((item) => (
                                            <MenuItem value={item.name_group_partners}>{item.name_group_partners}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                        </div>
                        <InputLabel id="demo-simple-select-label" className="label_form-add">Запчасти для ТО</InputLabel>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormGroup>
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="check-repair"
                                            name="oilFilter"
                                            checked={checkboxes.oilFilter}
                                            onChange={handleCheckboxChange}
                                        />
                                        Маслянный фильтр
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="check-repair"
                                            name="oilPlug"
                                            checked={checkboxes.oilPlug}
                                            onChange={handleCheckboxChange}
                                        />
                                        Маслянная пробка
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="check-repair"
                                            name="airFilter"
                                            checked={checkboxes.airFilter}
                                            onChange={handleCheckboxChange}
                                        />
                                        Воздушный фильтр
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="check-repair"
                                            name="cabinFilter"
                                            checked={checkboxes.cabinFilter}
                                            onChange={handleCheckboxChange}
                                        />
                                        Салонный фильтр
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="check-repair"
                                            name="dustFilter"
                                            checked={checkboxes.dustFilter}
                                            onChange={handleCheckboxChange}
                                        />
                                        Пылевой фильтр
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="check-repair"
                                            name="washingParticulateFilter"
                                            checked={checkboxes.washingParticulateFilter}
                                            onChange={handleCheckboxChange}
                                        />
                                        Мойка Сажевого фильтра
                                    </label>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6}>
                                <FormGroup>
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="check-repair"
                                            name="frontPads"
                                            checked={checkboxes.frontPads}
                                            onChange={handleCheckboxChange}
                                        />
                                        Передние колодки
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="check-repair"
                                            name="rearPads"
                                            checked={checkboxes.rearPads}
                                            onChange={handleCheckboxChange}
                                        />
                                        Задние колодки
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="check-repair"
                                            name="frontBrakePads"
                                            checked={checkboxes.frontBrakePads}
                                            onChange={handleCheckboxChange}
                                        />
                                        Передние диски
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="check-repair"
                                            name="rearBrakePads"
                                            checked={checkboxes.rearBrakePads}
                                            onChange={handleCheckboxChange}
                                        />
                                        Задние диски
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="check-repair"
                                            name="fuelFilter"
                                            checked={checkboxes.fuelFilter}
                                            onChange={handleCheckboxChange}
                                        />
                                        Топливный фильтр
                                    </label>
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <InputLabel id="demo-simple-select-label" className="label_form-add">Примечание</InputLabel>
                        <TextareaAutosize
                            id="outlined-basic"
                            className="textArea_bodyText_tech"
                            label="Имя"
                            variant="outlined"
                            type="textarea"
                            size="small"
                            sx={{ width: 1 }}
                            Value={commentRepair}
                            onChange={(event) => {
                                setCommentRepair(event.target.value);
                            }}
                        />
                        <InputLabel id="demo-simple-select-label" className="label_form-add">Заметки на след. ТО</InputLabel>
                        <TextareaAutosize
                            id="outlined-basic"
                            className="textArea_bodyText_tech"
                            label="Имя"
                            variant="outlined"
                            type="textarea"
                            size="small"
                            sx={{ width: 1 }}
                            Value={commentRepairNext}
                            onChange={(event) => {
                                setCommentRepairNext(event.target.value);
                            }}
                        />
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={createTechInsp}
                            className="btn-add-modal"
                        >
                            Сохранить
                        </Button>
                    </Box>
                </Modal>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style} className="box-modal-succes">
                        <Typography className="modal-grid-succes" id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                            <HighlightOffIcon className="modal-grid-delete-icon" />
                            <p className="modal-grid-succes-text">Карточка ремонта удалена!</p>
                            <a href="/repair" className="modal-grid-succes-text-a">Отлично</a>
                        </Typography>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}

export default RepairList;
