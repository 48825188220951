import React, { useState, useEffect } from "react";
import "../../App.css";
import { db } from "../../firebase-config";
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    getDoc,
} from "firebase/firestore";
import { Button, Chip, FormControl, Input, Modal, Select, TextField, Typography, Option, InputLabel, MenuItem, TextareaAutosize, Breadcrumbs, Alert, Link, Box, Grid } from "@mui/material";

import Header from "../../Header";
import Sidebar from "../../Sidebar";
import SaveIcon from '@mui/icons-material/Save';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import { useParams } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import FormGroup from '@mui/material/FormGroup';

const UpdateRepair = () => {
    const { id } = useParams();

    const [data, setData] = useState({
        gos: "",
        categoryRepair: "",
        garage: "",
        mileage: "",
        dateRepair: "",
        timeRepair: "",
        commentRepair: "",
        commentRepairNext: "",
        oilFilter: "",
        oilPlug: "",
        airFilter: "",
        cabinFilter: "",
        frontPads: "",
        rearPads: "",
        frontBrakePads: "",
        rearBrakePads: "",
    });


    const { gos, categoryRepair, garage, mileage, dateRepair, timeRepair, commentRepair, commentRepairNext,
        oilFilter, oilPlug, airFilter, cabinFilter, frontPads, rearPads, frontBrakePads, rearBrakePads } = data;

    useEffect(() => {
        id && getsingleAuto();
    }, [id]);

    const getsingleAuto = async () => {
        const docRef = doc(db, "repair", id);
        const snapshot = await getDoc(docRef);
        if (snapshot.exists()) {
            setData({ ...snapshot.data() });
        }
    };

    const handleInputChange = (event) => {
        setData({ ...data, [event.target.name]: event.target.value });
    };

    const updateData = async () => {
        const docRef = doc(db, "auto", id);
        await updateDoc(docRef, {
            gos: gos,
            categoryRepair: categoryRepair,
            garage: garage,
            mileage: mileage,
            dateRepair: dateRepair,
            timeRepair: timeRepair,
            commentRepair: commentRepair,
            commentRepairNext: commentRepairNext,
            oilFilter: oilFilter,
            oilPlug: oilPlug,
            airFilter: airFilter,
            cabinFilter: cabinFilter,
            frontPads: frontPads,
            rearPads: rearPads,
            frontBrakePads: frontBrakePads,
            rearBrakePads: rearBrakePads,
        });
        if (updateData) {
            handleOpen();
        } else {
            alert("ошибка загрузки");
        }
        // Добавьте здесь код для перехода на другую страницу, если необходимо
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div className="grid">
            <Header />
            <div class='content'>
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Дашборд
                        </Link>
                        <Link underline="hover" color="inherit" href="/auto">
                            Автомобили
                        </Link>
                        <Typography color="text.primary"><b>Редактирование ремонта</b></Typography>
                    </Breadcrumbs>
                </div>
                <p className="header-mt">{gos} - редактирование</p>
                <div className="info-active-block-table">
                    <Button
                        variant="outlined"
                        size="small"
                        className="btn-add-to-table"
                        onClick={updateData}
                    >
                        Сохранить
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        as={Link} to={'/orders'}
                        className="btn-add-to-table"
                    >
                        Отменить
                    </Button>
                    <div className="drivers-block-btn"></div>
                </div>
                <div className="grid-repair-view">
                    <div className="grid-1-block-repair-view">
                        <div className="grid-repair-view ">
                            <div className="gd-flex">
                                <p className="t-input-vw-repair ">Дата:</p>
                                <input
                                    className="input-update-auto inp-date-time"
                                    type="date"
                                    name="dateRepair"
                                    value={dateRepair}
                                />
                            </div>
                            <div className="gd-flex">
                                <p className="t-input-vw-repair ">Время:</p>
                                <input
                                    className="input-update-auto inp-date-time"
                                    type="time"
                                    name="timeRepair"
                                    value={timeRepair}
                                />
                            </div>
                        </div>
                        <div className="grid-repair-view ">
                            <div className="gd-flex">
                                <p className="t-input-vw-repair ">Категория:</p>
                                <input
                                    className="input-update-auto inp-date-time"
                                    type="text"
                                    name="categoryRepair"
                                    value={categoryRepair}
                                />
                            </div>
                            <div className="gd-flex">
                                <p className="t-input-vw-repair">Гараж:</p>
                                <input
                                    className="input-update-auto inp-date-time"
                                    type="text"
                                    name="garage"
                                    value={garage}
                                />
                            </div>
                        </div>
                        <div className="grid-repair-view ">
                            <div className="gd-flex">
                                <p className="t-input-vw-repair ">Категория:</p>
                                <input
                                    className="input-update-auto inp-date-time"
                                    type="text"
                                    name="mileage"
                                    value={mileage || "Пробег не вписан"}
                                />
                            </div>
                            <div className="gd-flex">
                            </div>
                        </div>
                        <div>
                            <p className="text-input-update-auto label-text-view">Расходные материалы:</p>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormGroup>
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="check-repair"
                                                name="oilFilter"
                                                checked={oilFilter}
                                            />
                                            Маслянный фильтр
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="check-repair"
                                                name="oilPlug"
                                                checked={oilPlug}
                                            //  onChange={handleCheckboxChange}
                                            />
                                            Маслянная пробка
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="check-repair"
                                                name="airFilter"
                                                checked={airFilter}
                                            //   onChange={handleCheckboxChange}
                                            />
                                            Воздушный фильтр
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="check-repair"
                                                name="cabinFilter"
                                                checked={cabinFilter}
                                            //     onChange={handleCheckboxChange}
                                            />
                                            Салонный фильтр
                                        </label>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormGroup>
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="check-repair"
                                                name="frontPads"
                                                checked={frontPads}
                                            //  onChange={handleCheckboxChange}
                                            />
                                            Передние колодки
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="check-repair"
                                                name="rearPads"
                                                checked={rearPads}
                                            //  onChange={handleCheckboxChange}
                                            />
                                            Задние колодки
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="check-repair"
                                                name="frontBrakePads"
                                                checked={frontBrakePads}
                                            // onChange={handleCheckboxChange}
                                            />
                                            Передние диски
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="check-repair"
                                                name="rearBrakePads"
                                                checked={rearBrakePads}
                                            //  onChange={handleCheckboxChange}
                                            />
                                            Задние диски
                                        </label>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <p className="text-input-update-auto label-text-view">Комментарий по ТО/Ремонту:</p>
                            <textarea
                                className="textArea-height"
                                type="text-area"
                                name="commentRepair"
                                value={commentRepair}
                            />
                        </div>
                        <div>
                            <p className="text-input-update-auto label-text-view">Рекомендации на след. ТО/Ремонт:</p>
                            <textarea
                                className="textArea-height"
                                type="text-area"
                                name="commentRepairNext"
                                value={commentRepairNext}
                            />
                        </div>
                    </div>
                    <div className="grid-2-block-repair-view">

                    </div>
                </div>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style} className="box-modal-succes">
                        <Typography className="modal-grid-succes" id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                            <CheckCircleIcon className="modal-grid-succes-icon" />
                            <p className="modal-grid-succes-text">Данные изменены</p>
                            <p className="modal-grid-succes-text">все отлично!</p>
                        </Typography>
                        <a href="/auto" className="modal-grid-succes-text-a">Вернуться в раздел Автомобили</a>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}

export default UpdateRepair;
