import React, { useState, useEffect } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import DoneIcon from "@mui/icons-material/Done";

const DocumentManager = ({ id }) => {
  const [documents, setDocuments] = useState({
    sts: { file: null, url: null },
    pts: { file: null, url: null },
    osago: { file: null, url: null },
    diagnosticCard: { file: null, url: null },
  });

  useEffect(() => {
    const fetchDocuments = async () => {
      const storage = getStorage();
      const documentTypes = {
        sts: "STS",
        pts: "ПТС",
        osago: "ОСАГО",
        diagnosticCard: "DiagnosticCard",
      };

      const updatedDocuments = { ...documents };

      for (const [key, fileName] of Object.entries(documentTypes)) {
        const storageRef = ref(storage, `docs/${id}/${fileName}_${id}`);
        try {
          const url = await getDownloadURL(storageRef);
          updatedDocuments[key] = { ...updatedDocuments[key], url };
        } catch (error) {
          console.error(`Error fetching ${fileName}:`, error);
        }
      }

      setDocuments(updatedDocuments);
    };

    fetchDocuments();
  }, [id]);

  const handleFileChange = (documentType, event) => {
    const file = event.target.files[0];
    setDocuments((prev) => ({
      ...prev,
      [documentType]: { ...prev[documentType], file },
    }));
  };

  const handleFileAction = async (documentType, isReplacement = false) => {
    const { file } = documents[documentType];
    if (!file) {
      console.log("No file selected");
      return;
    }

    const storage = getStorage();
    const documentNames = {
      sts: "STS",
      pts: "ПТС",
      osago: "ОСАГО",
      diagnosticCard: "DiagnosticCard",
    };

    const fileName = `${documentNames[documentType]}_${id}`;

    try {
      const storageRef = ref(storage, `docs/${id}/${fileName}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);

      setDocuments((prev) => ({
        ...prev,
        [documentType]: { ...prev[documentType], url },
      }));

      alert(`Файл успешно ${isReplacement ? "заменен" : "загружен"}!`);
      window.location.reload();
    } catch (error) {
      console.error("Error handling file:", error);
      alert(`Ошибка при ${isReplacement ? "замене" : "загрузке"} файла`);
    }
  };

  const documentLabels = {
    sts: "СТС (2 стр.)",
    pts: "Паспорт Т/С",
    osago: "ОСАГО",
    diagnosticCard: "Диагностическая карта",
  };

  const renderDocument = (documentType) => {
    const { file, url } = documents[documentType];
    const label = documentLabels[documentType];

    return (
      <div className="document-card">
        <div className="document-main">
          <div className="document-icon">
            {url ? (
              <FolderIcon className="folder-icon uploaded" size={30} />
            ) : (
              <FolderOffIcon className="folder-icon not-uploaded" />
            )}
          </div>

          <div className="document-details">
            <div className="document-title">{label}</div>
            <span
              className={`document-status ${url ? "uploaded" : "not-uploaded"}`}
            >
              {url ? "Загружено" : "Нет файла"}
            </span>
          </div>

          <div className="document-actions">
            {url ? (
              <>
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="action-btn view"
                >
                  Просмотр
                </a>
                <input
                  type="file"
                  id={`file-input-${documentType}`}
                  onChange={(e) => handleFileChange(documentType, e)}
                  className="file-input"
                />
                <label
                  htmlFor={`file-input-${documentType}`}
                  className="action-btn replace"
                >
                  Замена
                </label>
                {file && (
                  <button
                    onClick={() => handleFileAction(documentType, true)}
                    className="action-btn confirm"
                  >
                    <DoneIcon />
                  </button>
                )}
              </>
            ) : (
              <>
                <input
                  type="file"
                  id={`file-input-${documentType}`}
                  onChange={(e) => handleFileChange(documentType, e)}
                  className="file-input"
                />
                <label
                  htmlFor={`file-input-${documentType}`}
                  className="action-btn upload"
                >
                  Выбрать
                </label>
                {file && (
                  <button
                    onClick={() => handleFileAction(documentType)}
                    className="action-btn confirm"
                  >
                    <DoneIcon />
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        {file && <div className="selected-file">{file.name}</div>}
      </div>
    );
  };

  return (
    <div className="document-container">
      <style>
        {`
          .document-container {
            gap: 16px;
            flex-wrap: nowrap;
            overflow-x: auto;
            width: 100%;
          }

          .document-card {
                flex: 0 0 300px;
    background: white;
    padding: 0px;
    border-bottom: 1px solid #ededed;
          }

          .document-main {
            display: flex;
            align-items: center;
            gap: 12px;
            min-height: 48px;
          }

          .document-icon {
            flex-shrink: 0;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .folder-icon {
            font-size: 40px !important;
          }

          .folder-icon.uploaded {
            color: #FFB74D;
          }

          .folder-icon.not-uploaded {
            color: #9E9E9E;
          }

          .document-details {
            flex: 1;
            min-width: 0;
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .document-title {
            font-size: 14px;
            font-weight: 500;
            color: #333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .document-status {
            font-size: 12px;
            padding: 2px 8px;
            border-radius: 4px;
            display: inline-block;
            white-space: nowrap;
          }

          .document-status.uploaded {
            background: #E8F5E9;
            color: #2E7D32;
                width: fit-content;
          }

          .document-status.not-uploaded {
            background: #FFEBEE;
            color: #C62828;
                width: fit-content;
          }

          .document-actions {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-left: auto;
          }

          .file-input {
            display: none;
          }

          .action-btn {
            padding: 6px 12px;
            border-radius: 4px;
            border: none;
            font-size: 12px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease;
            text-decoration: none;
            white-space: nowrap;
          }

          .action-btn.view {
            background: #E3F2FD;
            color: #1976D2;
          }

          .action-btn.upload {
            background: #1976D2;
            color: white;
            min-width: 80px;
          }

          .action-btn.replace {
            background: #F5F5F5;
            color: #424242;
            min-width: 80px;
          }

          .action-btn.confirm {
            background: #4CAF50;
            color: white;
            padding: 6px;
            width: 32px;
            height: 28px;
          }

          .action-btn:hover {
            opacity: 0.9;
          }

          .selected-file {
            font-size: 12px;
            color: #666;
            margin-top: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 52px;
          }

          @media (max-width: 768px) {
            .document-container {
              padding: 8px 0;
              gap: 8px;
            }

            .document-card {
              flex: 0 0 280px;
            }

            .action-btn {
              padding: 4px 8px;
              font-size: 11px;
            }
          }
        `}
      </style>
      {renderDocument("sts")}
      {renderDocument("pts")}
      {renderDocument("osago")}
      {renderDocument("diagnosticCard")}
    </div>
  );
};

export default DocumentManager;
