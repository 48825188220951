import React, { useState, useEffect } from "react";
import "../../App.css";
import { db } from "../../firebase-config";
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
} from "firebase/firestore";
import { Button, Chip, FormControl, Input, Modal, Select, TextField, Typography, Option, InputLabel, MenuItem, TextareaAutosize, Breadcrumbs, Alert, Link, Box, Grid } from "@mui/material";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import SaveIcon from '@mui/icons-material/Save';
import NoCrashIcon from '@mui/icons-material/NoCrash';


const AddDriver = () => {
    const [newMarka, setNewMarka] = useState("");
    const [newModel, setNewModel] = useState("");
    const [newGos, setNewGos] = useState("");
    const [newClasse, setNewClasse] = useState("");
    const [colorAuto, setColorAuto] = useState("");
    const [typeAuto, setTypeAuto] = useState("");
    const [driveAuto, setDriveAuto] = useState("");
    const [fuelAuto, setFuelAuto] = useState("");
    const [vin, setVin] = useState("");
    const [year, setYear] = useState("");
    const [newType, setNewType] = useState("");

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [patronymic, setPatronymic] = useState("");
    const [phone, setPhone] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [birthplace, setBirthplace] = useState("");
    const [passportNumber, setPassportNumber] = useState("");
    const [issuedBy, setIssuedBy] = useState("");
    const [issueDate, setIssueDate] = useState("");
    const [divisionCode, setDivisionCode] = useState("");
    const [registration, setRegistration] = useState("");




    const driversCollectionRef = collection(db, "drivers");

    const createDriver = async () => {
        await addDoc(driversCollectionRef, {
            name: name,
            surname: surname,
            patronymic: patronymic,
            phone: phone,
        });
        if (createDriver) {
            handleOpen();
        } else {
            alert("ошибка загрузки");
        }
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div className="grid">
            <Header />
            <div class='content'>
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Дашборд
                        </Link>
                        <Link underline="hover" color="inherit" href="/auto">
                            Автомобили
                        </Link>
                        <Typography color="text.primary"><b>Добавление водителя</b></Typography>
                    </Breadcrumbs>
                </div>
                <p className="header-mt">Добавление водителя</p>
                <div className="info-active-block-table">
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={createDriver}
                        className="btn-save-to-table"
                    >
                        Сохранить
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        href="/auto"
                        className="btn-back-to-table"
                    >
                        Отменить
                    </Button>
                    <div className="drivers-block-btn"></div>
                </div>
                <div className="box-block-edit-params">
                    <div className="box-create-form">
                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={1}>
                                <p className="text-input-update-auto">Имя:</p>
                            </Grid>
                            <Grid item xs={3}>
                                <input
                                    className="input-update-auto"
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={1}>
                                <p className="text-input-update-auto">Фамилия:</p>
                            </Grid>
                            <Grid item xs={3}>
                                <input
                                    className="input-update-auto"
                                    type="text"
                                    name="surname"
                                    value={surname}
                                    onChange={(e) => setSurname(e.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={1}>
                                <p className="text-input-update-auto">Отчество:</p>
                            </Grid>
                            <Grid item xs={3}>
                                <input
                                    className="input-update-auto"
                                    type="text"
                                    name="patronymic"
                                    value={patronymic}
                                    onChange={(e) => setPatronymic(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={1}>
                                <p className="text-input-update-auto">Телефон:</p>
                            </Grid>
                            <Grid item xs={3}>
                                <input
                                    className="input-update-auto"
                                    type="number"
                                    name="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <p className="p-big-update" >Паспорт:</p>
                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={2}>
                                <p className="text-for-input-update-auto-2-2">Серия / номер:</p>
                            </Grid>
                            <Grid item xs={2}>
                                <input
                                    className="input-update-auto"
                                    type="text"
                                    name="insuranceCompany"

                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={2}>
                                <p className="text-for-input-update-auto-2-2">Кем выдан:</p>
                            </Grid>
                            <Grid item xs={2}>
                                <input
                                    className="input-update-auto"
                                    type="text"
                                    name="insuranceNumber"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={2}>
                                <p className="text-for-input-update-auto-2-2">Дата выдачи:</p>
                            </Grid>
                            <Grid item xs={2}>
                                <input
                                    className="input-update-auto"
                                    type="text"
                                    name="insuranceNumber"

                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={2}>
                                <p className="text-for-input-update-auto-2-2">Код подразделения:</p>
                            </Grid>
                            <Grid item xs={2}>
                                <input
                                    className="input-update-auto"
                                    type="text"
                                    name="insuranceNumber"

                                />
                            </Grid>
                        </Grid>
                        <p className="p-big-update" >Адреса:</p>
                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={2}>
                                <p className="text-for-input-update-auto-2-2">Прописан по адресу:</p>
                            </Grid>
                            <Grid item xs={2}>
                                <input
                                    className="input-update-auto"
                                    type="text"
                                    name="insuranceCompany"

                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={2}>
                                <p className="text-for-input-update-auto-2-2">Место проживания:</p>
                            </Grid>
                            <Grid item xs={2}>
                                <input
                                    className="input-update-auto"
                                    type="text"
                                    name="insuranceNumber"

                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={2}>
                                <p className="text-for-input-update-auto-2-2">Доп.информация:</p>
                            </Grid>
                            <Grid item xs={2}>
                                <input
                                    className="input-update-auto"
                                    type="text"
                                    name="insuranceNumber"

                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style} className="box-modal-succes">
                        <Typography className="modal-grid-succes" id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                            <NoCrashIcon className="modal-grid-succes-icon" />
                            <p className="modal-grid-succes-text">Водитель добавлен!</p>
                        </Typography>
                        <a href="/auto" className="modal-grid-succes-text-a">Вернуться в раздел Автомобили</a>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}

export default AddDriver;
